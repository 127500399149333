<template>
  <Modal
    v-model:visible="showModal"
    header="Add more accounts from linked banks?"
    :modal="true"
    :draggable="false"
    append-to="self"
  >
    <div :class="$style['modal-content']">
      <div v-if="selectedBank != null" :class="$style['warning-message']">
        Please select all accounts associated with your business including<br />
        the account previously set as primary for debit proposes
      </div>
      <div
        :class="$style.banks"
        :style="{ justifyContent: plaidBanks?.length < 3 && 'center' }"
      >
        <BankTile
          v-for="bank in plaidBanks"
          :class="$style.banks__item"
          :selected="selectedBank === bank"
          :bank-logo="bank.logo"
          :bank-name="bank.bankName"
          data-cy="bank-tile-to-add-account"
          @click="selectedBank = bank"
        />
      </div>
    </div>

    <template #footer>
      <div :class="$style.footer">
        <DSButton
          label="No, connect with new institution"
          class="p-button-outlined"
          :class="$style.footer__button"
          @click="connectNewInstitution"
        />
        <DSButton
          label="Next"
          type="submit"
          :class="$style.footer__button"
          :disabled="!selectedBank"
          @click="connectExistingInstitution"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import BankTile from '@/components/BankTile';

export default {
  emits: ['connect-new', 'connect-existing'],
  components: {
    Modal,
    DSButton,
    BankTile,
  },
  props: {
    plaidBanks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      selectedBank: null,
    };
  },
  computed: {},
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    connectNewInstitution() {
      this.$emit('connect-new');
      this.closeModal();
    },
    async connectExistingInstitution() {
      this.$emit('connect-existing', this.selectedBank.accounts[0]);
      this.closeModal();
    },
  },
};
</script>

<style lang="less" module>
[class^='p-dialog'] {
  max-width: none;
}

body .footer button.footer__button {
  margin: 0;
  width: 100%;
}

body .footer button.footer__button + button.footer__button {
  margin-top: 16px;
}

@media (min-width: 560px) {
}

@media (min-width: 700px) {
  .footer {
    min-width: 560px;
  }

  body .footer button.footer__button {
    margin: 0;
    width: calc(50% - 8px);
    white-space: nowrap;
  }

  body .footer button.footer__button + button.footer__button {
    margin: 0 0 0 16px;
  }
}

.warning-message {
  margin-bottom: 25px;
}

.banks {
  max-width: 560px;
  display: flex;
  flex-wrap: wrap;
}

.banks__item {
  width: 100%;
  margin-top: 16px;
}

.banks__item:focus {
  outline: none;
}

.banks__item__image {
  width: 175px;
  height: 80px;
  border: 1px solid red;
  display: block;
}

.banks__item__title {
  display: block;
}

@media (min-width: 400px) and (max-width: 699px) {
  .banks__item {
    width: calc(50% - 14px);
  }

  .banks__item + .banks__item {
    margin-left: 14px;
  }

  .banks__item:nth-child(-n + 2) {
    margin-top: 0;
  }

  .banks__item:nth-child(3n) {
    margin-left: 0;
  }
}

@media (min-width: 700px) {
  .banks__item {
    width: calc(33.3% - 14px);
    margin-left: 14px;
  }

  .banks__item:nth-child(-n + 3) {
    margin-top: 0;
  }

  .banks__item:nth-child(4n) {
    margin-left: 0;
  }
}
</style>
