<template>
  <Modal
    v-model:visible="showModal"
    :modal="true"
    header="Important"
    :draggable="false"
    append-to="self"
  >
    Please select all accounts associated with <br />
    your business including the account previously <br />
    set as primary for debit proposes
    <template #footer>
      <div :class="$style.footer">
        <DSButton
          label="ok"
          type="submit"
          :class="$style.footer__button"
          @click="goNext"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';

export default {
  emits: ['confirm'],
  components: {
    Modal,
    DSButton,
  },
  data() {
    return {
      showModal: false,
      newBankAccountName: '',
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    goNext() {
      this.$emit('confirm');
      this.closeModal();
    },
  },
};
</script>

<style lang="less" module>
body .footer button.footer__button {
  margin: 0;
  width: calc(50% - 8px);
}

body .footer button.footer__button + button.footer__button {
  margin-left: 16px;
}
</style>
