<template>
  <LayoutForm :class="$style.form">
    <InputWithEyebrow
      v-if="collectRegistrationNumber"
      ref="registrationNumber"
      :model-value="business.registrationNumber"
      name="registrationNumber"
      required
      type="integer"
      :label="registrationNumberLabel(business.corpCountry)"
      :special-number-regex="/^[a-zA-Z0-9\s\-]+$/"
      :validate-on-blur="showError"
      :error="showError && !isValidRegistrationNumber"
      :placeholder="registrationNumberLabel(business.corpCountry)"
      @input="updateBusinessParams"
    />
    <InputWithEyebrow
      ref="corpDate"
      :model-value="business.corpDate"
      name="corpDate"
      required
      type="date"
      :label="$t('common.businessIncorporationDate')"
      :validate-on-blur="showError"
      :error="!business.corpDate && showError"
      :max-date="new Date()"
      :placeholder="$t('common.businessIncorporationDate')"
      :eyebrow="$t('common.businessIncorporationDate')"
      @input="updateBusinessParams"
    />
    <LayoutUploadFileContainer :class="$style['upload-container']">
      <Subtitle>{{
        $t('components.formBusinessFormation.uploadIncorporationArticles')
      }}</Subtitle>
      <div :class="$style['upload-info']">
        <i class="pi pi-info-circle" />
        Please provide the official document issued when your company was formed
        (e.g. Articules of Incorporation)
      </div>
      <UploadFileWithDownloadableFileList
        ref="upload-file-with-downloadable-file-list"
        :upload-filters="incorporationFilters"
        :display-filters="incorporationUploadMatcher"
        :empty-prompt="messagePrompt"
        theme="onboarding"
        no-list
        :files="incorporationDocs"
        @trackEvent="trackEvent('fe_upload_doc_click')"
        @uploadDragged="trackUploadDragged"
        @docsUploaded="trackDocsUploaded"
        @uploadFailed="trackUploadFailed"
        @trackUploadedDocClicked="trackUploadedDocClicked"
        @fileRemove="removeUpload"
      />
    </LayoutUploadFileContainer>
    <LayoutUploadFileContainer :class="$style['upload-container']">
      <Subtitle>{{
        $t('components.formBusinessFormation.uploadEinDocument')
      }}</Subtitle>
      <div :class="$style['upload-info']">
        <i class="pi pi-info-circle" />
        Please upload the official EIN verification letter your business
        received from the IRS after applying for an EIN. You can do this now or
        anytime before funding.
      </div>
      <UploadFileWithDownloadableFileList
        ref="upload-file-with-downloadable-file-list"
        :upload-filters="einFilters"
        :empty-prompt="messagePrompt"
        theme="onboarding"
        no-list
        :files="einDocuments"
        @trackEvent="trackEvent('fe_upload_doc_click')"
        @uploadDragged="trackUploadDragged"
        @docsUploaded="trackDocsUploaded"
        @uploadFailed="trackUploadFailed"
        @trackUploadedDocClicked="trackUploadedDocClicked"
        @fileRemove="removeUpload"
      />
    </LayoutUploadFileContainer>
  </LayoutForm>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { isMobileMixin } from '@/utils/vue-mixins';
import { ENTITY_TYPE, BUSINESS_DOC_TYPE } from '@clearbanc/data-common-types';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import { validateChildren } from '@/composables/validation';
import { registrationNumberLabel } from '@/utils/local';
import analytics from '@/utils/analytics';
import Subtitle from '@/components/Subtitle';
import { UploadFileWithDownloadableFileList, LayoutForm } from '@/components';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import LayoutUploadFileContainer from '@/components/layouts/LayoutUploadFileContainer';
import { getParsedMessage } from '@/data/error-messages';

export default {
  components: {
    InputWithEyebrow,
    UploadFileWithDownloadableFileList,
    LayoutUploadFileContainer,
    Subtitle,
    LayoutForm,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    collectRegistrationNumber: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      showError: false,
      corpDate: null,
    };
  },
  computed: {
    ...mapGetters([
      'productSegmentLabel',
      'business',
      'user',
      'incorporationUploadMatcher',
      'einDocuments',
    ]),
    ...mapRequestStatuses({
      updateBusinessRequest: 'UPDATE_BUSINESS',
    }),
    docsNotUploadedError() {
      return this.$t('components.formBusinessFormation.docsNotUploadedError');
    },
    incorporationFilters() {
      return {
        entity: ENTITY_TYPE.BUSINESS,
        type: BUSINESS_DOC_TYPE.CORP_DOCS,
        businessId: this.business.id,
      };
    },
    einFilters() {
      return {
        entity: ENTITY_TYPE.BUSINESS,
        type: BUSINESS_DOC_TYPE.EIN_DOC,
        businessId: this.business.id,
      };
    },
    incorporationDocs() {
      return this.$store.getters.uploadsMatching(
        this.incorporationUploadMatcher,
      );
    },
    messagePrompt() {
      return this.isMobile()
        ? this.$t('common.messagePrompt.browse')
        : this.$t('common.messagePrompt.dragAndDropFilesOrBrowse');
    },
    incorporationDocsUploaded() {
      return this.incorporationDocs?.length > 0;
    },
    isUSIncorporatedBusiness() {
      return this.business.corpCountry === COUNTRY_CODES.US;
    },
    isValidRegistrationNumber() {
      const numericOnlyRegistrationNumber =
        this.business?.registrationNumber?.replace(/\D/g, '');
      return !!numericOnlyRegistrationNumber;
    },
  },
  methods: {
    updateBusinessParams(val, name) {
      this.$store.commit('UPDATE_BUSINESS_PARAMS', {
        [name]: val,
      });
    },
    trackEvent(eventName) {
      analytics.track(eventName, this.eventTrackingFields);
    },
    trackUploadDragged(numDocsDragged) {
      analytics.track('fe_upload_dragged', {
        ...this.eventTrackingFields,
        numDocs: numDocsDragged,
      });
    },
    trackUploadedDocClicked(numDocsDragged) {
      analytics.track('fe_uploaded_doc_clicked', {
        ...this.eventTrackingFields,
        numDocs: numDocsDragged,
      });
    },
    trackDocsUploaded(uploadData) {
      analytics.track('fe_docs_uploaded', {
        ...this.eventTrackingFields,
        numDocs: uploadData.numDocs,
        userId: this.user.id,
        businessId: this.business.id,
      });
      this.$emit('docsUploaded');
    },
    trackUploadFailed(uploadData) {
      analytics.track('fe_upload_failed', {
        ...this.eventTrackingFields,
        userId: this.user.id,
        businessId: this.business.id,
        errorMsg: uploadData.errorMessage,
      });
    },
    removeUpload(id) {
      this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
      this.trackEvent('fe_doc_deleted');
    },
    async validateForm() {
      this.showError = true;
      // mixin will validate input fields
      if (!this.incorporationDocsUploaded) {
        this.$emit('validationFailure', this.docsNotUploadedError);
        return;
      }
      const error = await this.hasError();
      if (error) {
        return;
      }
      // manual validation for incorporation documents
      if (
        this.incorporationDocsUploaded &&
        (this.isValidRegistrationNumber || !this.collectRegistrationNumber)
      ) {
        await this.$store.dispatchApiAction('UPDATE_BUSINESS', {
          corpDate: this.business.corpDate,
          registrationNumber: this.business.registrationNumber,
          ...(this.isUSIncorporatedBusiness &&
            this.business.federalTaxId && {
              federalTaxId: this.business.federalTaxId,
            }),
        });
        if (this.updateBusinessRequest.isError) {
          this.$emit(
            'validationFailure',
            getParsedMessage(this.updateBusinessRequest.error, true),
          );
          return;
        }
        this.$emit('validationSuccess');
      }
    },
    registrationNumberLabel,
  },
};
</script>
<style lang="less" module>
.form {
  max-width: 335px;
  margin: auto auto;
}
.upload-container {
  margin-top: 40px;
}
.upload-info {
  padding-bottom: 6px;
  font-size: 14px;
  color: #666;
  i {
    font-size: 12px;
  }
}
</style>
