import { ContactSupportMethods } from '@/data/contact-support';
import i18n from '@/plugins/i18n';

export const CONTACT_SUPPORT_MSG = (contactNumber) =>
  i18n.global.t('data.errorMessages.contactSupportMsg', {
    contactSupportMethodsPhoneDisplay: contactNumber,
    contactSupportMethodsEmailDisplay: ContactSupportMethods.email.display,
  });

const contactSupportMethodsPhoneDisplay = `${ContactSupportMethods.phone.display} (${ContactSupportMethods.supportHours.display})`;
export const CONTACT_SUPPORT_SUFFIX = i18n.global.t(
  'data.errorMessages.contactSupportSuffix',
  {
    contactSupportMethodsPhoneDisplay,
    contactSupportMethodsEmailDisplay: ContactSupportMethods.email.display,
  },
);

export const DISPLAYABLE_ERROR_CODES = {
  BAUS0001: {},
  // '{lockedProp}' is currently locked and cannot be modified
  USER001: {
    i18nKey: 'backendError.USER001', // No valid Heron PDFs found for the business.
    support: true,
  },
  // 'Contract signing is limited to owners. Ensure you have the necessary ownership privileges to proceed.
  USER011: {},
  // There was an error uploading your PDF. Please try again
  HERON001: {},
  // It was not possible to upload your PDF. Please contact support at support@clear.co
  HERON002: {
    support: true,
  },
  // File too large to heron service
  HERON003: {},
  H_PDF_001: {
    i18nKey: 'backendError.H_PDF_001', // No valid Heron PDFs found for the business.
    static: "Please upload last month's statement.",
  },
  H_PDF_002: {
    i18nKey: 'backendError.H_PDF_002', // The last statement date exceeds {statementEndLimitInDays} days.
  },
  H_PDF_003: {
    i18nKey: 'backendError.H_PDF_003', // There was an error uploading your PDF. Please try again
  },
  H_PDF_004: {
    i18nKey: 'backendError.H_PDF_004', // The end date of the last statement ({statementEndDate}) does not match the expected end date: {pdfEndDate}.
  },
  H_PDF_005: {
    i18nKey: 'backendError.H_PDF_005', // Statements don't cover the initial date: {date}
  },
  H_PDF_006: {
    i18nKey: 'backendError.H_PDF_006', // Data for {reference} is missing from {startDate} to {endDate}
  },
  // Currency Cloud Verification Failures
  CCVF001: {
    i18nKey: 'backendError.CCVF001', // Verification Result: {answer}. The account number and sort code provided for the beneficiary do not match those on record.
  },
  CCVF002: {
    i18nKey: 'backendError.CCVF002', // Verification Result: {answer}. The name you have provided for the beneficiary does not match that on record.
  },
  CCVF003: {
    i18nKey: 'backendError.CCVF003', // Verification Result: {answer}. It has not been possible to check the beneficiary details you have provided.
  },
  CCVF004: {
    i18nKey: 'backendError.CCVF004', // Verification Result: {answer}. The reference details you have provided for the beneficiary do not match those on record.
  },
  CCVF005: {
    i18nKey: 'backendError.CCVF005', // Verification Result: {answer}. The details you have provided for the beneficiary closely match those on record, however the name is {actualName}.
  },
  CCVF006: {
    i18nKey: 'backendError.CCVF006', // Verification Result: {answer}. The details you have provided for the beneficiary closely match those on record, however the company name is {actualName}.
  },
  CCVF007: {
    i18nKey: 'backendError.CCVF007', // Verification Result: {answer}. The details you have provided for the beneficiary closely match those on record, however the name is {actualName}.
  },
  CCVF008: {
    i18nKey: 'backendError.CCVF008', // Verification Result: {answer}. The details you have provided for the beneficiary closely match those on record, however the beneficiary is recognised as a company not an individual.
  },
  CCVF009: {
    i18nKey: 'backendError.CCVF009', // Verification Result: {answer}. The details you have provided for the beneficiary closely match those on record, however it is recognised as an individual not a company.
  },
  CCVF010: {
    i18nKey: 'backendError.CCVF010', // Verification Result: {answer}. The details you have provided for the beneficiary closely match those on record, however the account has been switched to a different organisation.
  },
  CCVF011: {
    i18nKey: 'backendError.CCVF011', // Verification Result: {answer}. It has not been possible to check the beneficiary details you have provided.
  },
};

// Errors below appends "Please contact us at support@clear.co" to the message
export const shouldDisplayContactSupport = (errorCode) => {
  return errorCode && DISPLAYABLE_ERROR_CODES[errorCode]?.support;
};

// @deprecated Use 'getParsedMessage' instead.
export const getDisplayedErrorMessage = (error, withContactSupportSuffix) => {
  const { errorCode, message } = error;
  const displayableError = DISPLAYABLE_ERROR_CODES[errorCode];
  if (displayableError) {
    const { static: staticMessage, i18nKey } = displayableError;
    if (staticMessage) return staticMessage;
    if (i18nKey) return i18nKey;
    if (message) {
      const messageWithPeriod = message.endsWith('.') ? message : `${message}.`;
      return withContactSupportSuffix && errorCode.support
        ? `${messageWithPeriod} ${CONTACT_SUPPORT_SUFFIX}`
        : messageWithPeriod;
    }
  }
  return undefined;
};

export const getParsedMessage = (error, withContactSupportSuffix) => {
  const { errorCode, details } = error ?? {};
  const displayableError = DISPLAYABLE_ERROR_CODES[errorCode];
  if (displayableError) {
    let message = error.message;
    const { static: staticMessage, i18nKey, support } = displayableError;
    if (staticMessage) return staticMessage;
    if (i18nKey) {
      message = i18n.global.t(i18nKey, details);
    }
    const messageWithPeriod = message.endsWith('.') ? message : `${message}.`;
    const titleCase =
      messageWithPeriod.charAt(0).toUpperCase() + messageWithPeriod.slice(1);

    return withContactSupportSuffix && support
      ? `${titleCase} ${CONTACT_SUPPORT_SUFFIX}`
      : titleCase;
  }
  return undefined;
};
