import {
  PAYMENTS_CONFIG,
  PAYMENTS_EXPERIMENT_NAME,
  PAYMENTS_ROUTE_NAMES,
  PAYMENTS_STEPS,
  V2_CAPACITY_ERROR_TYPES,
  BillStatus,
} from '@/data/payments';
import {
  SelfRevenueRanges,
  SelfRevenueRangeToSelfReportedCapacityCents,
} from '@/data/self-revenue-range-offers';
// reusing helpers from onboarding module
import {
  findIncompleteOwner,
  isBankLinkConfirmationStepComplete,
  isBizProfileBusinessDetailsStepComplete,
  isBusinessFormationDocsStepComplete,
  isConnectBankingStepComplete,
  isOwnersComplete,
  isOwnersListStepComplete,
  isOwnersStepOneComplete,
  isUploadBankStatementsStepComplete,
} from '@/utils/onboarding-steps';
import { paymentsRequest } from '@/utils/payments-request';
import { beneficiaryRequest } from '@/utils/beneficiary-request';
import { buildApiActions } from '@/utils/vuex-api-utils';
import { stateOptionsForCountry } from '@/utils/local';
import { CAPACITY } from '@clearbanc/data-common-types';

const CAPACITY_BOTTOM_OUT_CENTS = 100000; // $1000.00

export function isUserProfileStepComplete(user) {
  return !!(user.firstName && user.lastName && user.phoneNumber);
}

export function isOnboardingBusinessDetailsComplete(business) {
  const isMonthlyRevInList = Object.values(SelfRevenueRanges).includes(
    business?.qualifyingQuestions?.monthly_revenue,
  );

  return !!(
    business.corpCountry &&
    (business.corpState || !stateOptionsForCountry(business.corpCountry)) &&
    business.qualifyingQuestions?.monthly_revenue &&
    isMonthlyRevInList &&
    business.name &&
    business.website
  );
}

export function isRegisteredAddressComplete(business) {
  return !!(
    business.address?.line1 &&
    business.address?.city &&
    business.address?.country &&
    (business.address?.state ||
      !stateOptionsForCountry(business.address?.country)) &&
    business.address?.postalCode
  );
}

export function isBusinessProfileCompleteFn(rootGetters) {
  return !!(
    rootGetters.bankingAccountsConnected &&
    rootGetters.isBusinessDetailsStepComplete &&
    rootGetters.isBusinessOwnersStepComplete &&
    (!rootGetters.debtObligationsRequired ||
      rootGetters.businessDebtObligations?.id)
  );
}

export default {
  state: {
    defaultConfigs: PAYMENTS_CONFIG,
    experimentName: PAYMENTS_EXPERIMENT_NAME,
    featureFlags: {},
    paymentsOnboardingSteps: {
      [PAYMENTS_ROUTE_NAMES.SIGN_UP]: {
        name: PAYMENTS_ROUTE_NAMES.SIGN_UP,
        nextRoute: { name: PAYMENTS_ROUTE_NAMES.USER_PROFILE },
        isComplete: false,
        title: 'Sign up',
      },
      [PAYMENTS_ROUTE_NAMES.USER_PROFILE]: {
        title: 'Profile Details',
        name: PAYMENTS_ROUTE_NAMES.USER_PROFILE,
        nextRoute: { name: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS },
        isComplete: false,
        percentComplete: 25,
      },
      [PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS]: {
        name: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS,
        title: 'Business Details',
        nextRoute: { name: PAYMENTS_ROUTE_NAMES.ADDRESS },
        isComplete: false,
        percentComplete: 50,
        previousRoute: { name: PAYMENTS_ROUTE_NAMES.USER_PROFILE },
      },
      [PAYMENTS_ROUTE_NAMES.ADDRESS]: {
        name: PAYMENTS_ROUTE_NAMES.ADDRESS,
        title: 'Address',
        subtitle: 'We need this info to review and verify your account',
        nextRoute: { name: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE },
        isComplete: false,
        percentComplete: 75,
        previousRoute: { name: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS },
      },
    },
    businessProfileSteps: {
      [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS]: {
        isComplete: false,
        isDisabled: true,
        title: 'Business Details',
        label: 'Add Business Details',
        name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS,
      },
      [PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS]: {
        isComplete: false,
        isDisabled: true,
        title: 'Business Owners/Directors Profiles',
        label: 'Add Business Owner Profiles',
        name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS,
      },
      [PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING]: {
        isComplete: false,
        isDisabled: true,
        title: 'Set Up Electronic Fund Transfer',
        subtitle: 'We automate debits so you never need to worry about it',
        label: 'Bank Accounts',
        name: PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING,
      },
      [PAYMENTS_ROUTE_NAMES.PROFILE_FINANCIAL_DETAILS]: {
        isComplete: false,
        isDisabled: true,
        title: 'Title',
        subtitle: 'Subtitle',
        label: 'Financial Details',
        name: PAYMENTS_ROUTE_NAMES.PROFILE_FINANCIAL_DETAILS,
      },
    },
    steps: PAYMENTS_STEPS,
    billsTotalCount: 0,
    bills: [],
    selectedBill: {},
    createdBill: {},
    rates: [],
    advertisedRates: {},
    repaymentSchedule: [],
    scheduledTransactions: [],
    beneficiaries: [],
    v2Beneficiaries: {},
    filteredRepayments: {},
    allRepayments: [],
    transactionsSummary: {},
    businessCapacity: {},
    businessCapacityResponseCode: 0,
    selectedBillBeneficiary: {},
    csvDownload: null,
    isReceiptFlow: false,
    beneficiaryFormInfo: {},
    emailVerified: false,
  },
  getters: {
    beneficiaryFormInfo: (state) => state.beneficiaryFormInfo,
    paymentsOnboardingSteps: (state) => state.paymentsOnboardingSteps,
    isEmailVerified: (state) => state.emailVerified,
    businessProfileSteps: (state, getters, rootState, rootGetters) => {
      const computedSteps = { ...state.businessProfileSteps };

      // Replaces where the `add business details` step takes you
      if (rootGetters.isPersonaEnabled) {
        Object.keys(state.businessProfileSteps).forEach((step) => {
          const existingStep = state.businessProfileSteps[step];
          if (
            existingStep.name === PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS
          ) {
            computedSteps[step] = {
              ...existingStep,
              name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS_PERSONA,
            };
          }
          if (
            existingStep.name === PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT
          ) {
            delete computedSteps[step];
          }
        });
      }

      delete computedSteps[PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT];

      return computedSteps;
    },
    steps: (state) => state.steps,
    firstIncompletePaymentsOnboardingStep: (state, getters) => {
      const incompleteStep = Object.values(
        getters.paymentsOnboardingSteps,
      ).find((val) => !val.isComplete);
      // If all steps are complete, redirect to payments dashboard
      if (!incompleteStep) {
        return { name: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE };
      }
      return incompleteStep;
    },
    beneficiaries: (state) => state.beneficiaries,
    billsTotalCount: (state) => state.billsTotalCount,
    bills: (state) => state.bills,
    maxCapacityExceeded: (state, getters) => {
      return (
        getters.businessCapacityGenerated &&
        Number(state.businessCapacity.usedCapacityCents) >
          Number(state.businessCapacity.capacityCents)
      );
    },
    scheduledTransactions: (state) => state.scheduledTransactions,
    repaymentSchedule: (state) => state.repaymentSchedule,
    advertisedRates: (state) => state.advertisedRates,
    selectedBill: (state) => state.selectedBill,
    createdBill: (state) => state.createdBill,
    isConnectBankingStepComplete: (state, getters, rootState, rootGetters) =>
      isConnectBankingStepComplete(rootGetters),
    isBankLinkConfirmationStepComplete: (
      state,
      getters,
      rootState,
      rootGetters,
    ) => isBankLinkConfirmationStepComplete(rootGetters),
    isBusinessDetailsStepComplete: (state, getters, rootState, rootGetters) =>
      isBizProfileBusinessDetailsStepComplete(
        rootGetters.business,
        rootGetters,
      ),
    isBusinessOwnersStepComplete: (state, getters, rootState, rootGetters) =>
      isOwnersComplete(getters),
    isUploadBankStatementsStepComplete: (
      state,
      getters,
      rootState,
      rootGetters,
    ) => isUploadBankStatementsStepComplete(rootGetters),
    filteredRepayments: (state) => state.filteredRepayments,
    allRepayments: (state) => state.allRepayments,
    transactionsSummary: (state) => state.transactionsSummary,
    experimentName: (state) => state.experimentName,
    featureFlags: (state) => state.featureFlags,
    bankingAccountsConnected: (state, getters, rootState, rootGetters) =>
      (rootGetters.isConnectBankingStepComplete &&
        rootGetters.isBankLinkConfirmationStepComplete) ||
      (rootGetters.isUploadBankStatementsStepComplete &&
        rootGetters.businessOnPlaidBypass),
    connectedAccountsConnected: (state, getters, rootState, rootGetters) =>
      rootGetters.hasActiveSalesAccounts && getters.bankingAccountsConnected,
    businessCapacity: (state) => state.businessCapacity,
    businessCapacityGenerated: (state) =>
      Object.keys(state.businessCapacity).length > 0,
    businessCapacityResponseCode: (state) => state.businessCapacityResponseCode,
    selectedBillBeneficiary: (state) => state.selectedBillBeneficiary,
    previousPaymentsStepName: (state, getters) => (currentStepName) => {
      const stepNames = Object.keys(getters.steps);
      return stepNames[stepNames.indexOf(currentStepName) - 1];
    },
    isBusinessProfileComplete: (state, rootGetters) =>
      isBusinessProfileCompleteFn(rootGetters),
    isBnplWaitlisted: (state, getters, rootState, rootGetters) =>
      // additional logic can be added here, such as per-user waitlisting
      !rootGetters.isUSBusiness && !rootGetters.userHasForceBnplFlag,

    userCanSubmitInvoices: (state, getters, rootState, rootGetters) => {
      // Cases for not being able to submit an invoice:
      // case 1: User has low rev = self-reported revenue under 10k AND capaciy is not generated yet
      // case 2: Remaining capacity is under 1k
      // case 3: backend returns disqualified
      // case 4: User has an unsettled MCA and has previous BILL advances and the BNPL force flag is FALSE
      // case 5: user does not have a connected primary bank account and capacity is calculated (behind feat flag)
      // case 6: capacity has not been calculated AND bill submission before verifying revenue is disabled
      // case 7: bill submission before verifying revenue is enabled AND business profile is not complete
      // case 8: bill submission before verifying revenue is enabled AND remaining self-reported capacity is <= 0 AND capacity is not generated
      const capacityRequest = rootGetters.requestStatus(
        'GET_BUSINESS_CAPACITY',
      );

      const selfReportedCapacityUnder10k =
        rootGetters.business.qualifyingQuestions?.monthly_revenue ===
        SelfRevenueRanges.from_0_to_10;

      const maxCents = getters.businessCapacity.remainingCapacityCents;

      const businessCapacityUnder1k = maxCents <= CAPACITY_BOTTOM_OUT_CENTS;

      const disqualifiedError =
        V2_CAPACITY_ERROR_TYPES.CAPACITY_DISQUALIFIED ===
        capacityRequest?.error?.type;

      const disconnectedPrimaryBankAccount =
        rootGetters.isPrimaryBankAccountDisconnected;

      return !(
        // case 1
        (
          (selfReportedCapacityUnder10k &&
            capacityRequest?.error?.type ===
              V2_CAPACITY_ERROR_TYPES.CAPACITY_NOT_FOUND) ||
          // case 2
          (capacityRequest.isSuccess && businessCapacityUnder1k) ||
          // case 3
          disqualifiedError ||
          // case 4
          (rootGetters.userHasActiveValidAdvances &&
            !rootGetters.userHasSettledValidAdvances &&
            rootGetters.userHasPreviousBillAdvances &&
            !rootGetters.userHasForceBnplFlag) ||
          // case 5
          (disconnectedPrimaryBankAccount && capacityRequest.isSuccess) ||
          // case 7
          !getters.isBusinessProfileComplete ||
          // case 8
          (getters.remainingSelfReportedCapacityCents <= 0 &&
            !getters.businessCapacityGenerated)
        )
      );
    },
    userIsDisqualified: (state, getters, rootState, rootGetters) => {
      const selfReportedCapacityUnder10k =
        rootGetters.business.qualifyingQuestions?.monthly_revenue ===
        SelfRevenueRanges.from_0_to_10;

      const capacityRequest = rootGetters.requestStatus(
        'GET_BUSINESS_CAPACITY',
      );

      const errorType = capacityRequest?.error?.type;

      return (
        !rootGetters.isUSBusiness ||
        [
          V2_CAPACITY_ERROR_TYPES.CAPACITY_DISQUALIFIED,
          V2_CAPACITY_ERROR_TYPES.DISCONNECTED_ECOM,
        ].includes(errorType) ||
        (selfReportedCapacityUnder10k &&
          errorType === V2_CAPACITY_ERROR_TYPES.CAPACITY_NOT_FOUND)
      );
    },
    selfReportedCapacityCents: (_state, _getters, _rootState, rootGetters) =>
      rootGetters.business?.qualifyingQuestions?.monthly_revenue
        ? SelfRevenueRangeToSelfReportedCapacityCents[
            rootGetters.business.qualifyingQuestions.monthly_revenue
          ]
        : 0,
    remainingSelfReportedCapacityCents: (state, getters) => {
      const totalUploadedBillAmountCents = state.bills
        .filter((bill) => bill.status !== BillStatus.CANCELLED)
        .reduce((acc, bill) => acc + parseInt(bill.amountCents), 0);
      return getters.selfReportedCapacityCents - totalUploadedBillAmountCents;
    },
    maxCapacityOverThreshold: (_state, getters, rootState, rootGetters) => {
      if (getters.businessCapacityGenerated) {
        const capacityCents = getters.businessCapacity?.capacityCents;
        return capacityCents >= CAPACITY.DOCUMENT_THRESHOLD;
      }
      return getters.selfReportedCapacityCents >= CAPACITY.DOCUMENT_THRESHOLD;
    },
    csvDownload: (state) => state.csvDownload,
    isReceiptFlow: (state) => state.isReceiptFlow,
    showNewProductBanner: () =>
      !localStorage.getItem('hide-phoenix-intro-banner'),
    v2Beneficiaries: (state) => Object.values(state.v2Beneficiaries),
    getV2BeneficiaryById: (state) => (id) => state.v2Beneficiaries[id],
  },
  ...buildApiActions(
    {
      CREATE_PAY_INVOICE: {
        action: (ctx, payload) => {
          return {
            method: 'post',
            interceptor: paymentsRequest,
            url: `/business/${ctx.rootGetters.user.businessId}/bills`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.createdBill = response;
        },
      },
      CANCEL_INVOICE: {
        action: (ctx, payload) => {
          return {
            method: 'post',
            interceptor: paymentsRequest,
            url: `/bills/${payload.billId}/cancel`,
            params: payload,
          };
        },
      },
      DOWNLOAD_PAYMENTS_CSV: {
        action: (ctx, payload) => {
          return {
            method: 'get',
            url: `/businesses/${ctx.rootGetters.user.businessId}/export-bills-transactions`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          const csvBlob = new Blob([response]);
          state.csvDownload = csvBlob;
        },
      },
      GET_BILLS: {
        action: (ctx) => {
          return {
            method: 'get',
            interceptor: paymentsRequest,
            url: `/business/${ctx.rootGetters.user.businessId}/bills`,
            params: {
              limit: 200,
            },
          };
        },
        mutation: (state, { response, responseTotalCount }) => {
          state.billsTotalCount = parseInt(responseTotalCount) ?? 0;
          state.bills = response;
        },
      },
      GET_BILL: {
        action: (ctx, payload) => {
          return {
            method: 'get',
            interceptor: paymentsRequest,
            url: `/bills/${payload.id}`,
            returnResponse: true,
          };
        },
        mutation: (state, { response }) => {
          state.selectedBill = response;
          const updatedBillIndex = state.bills.findIndex(
            (bill) => bill.id === response.id,
          );
          if (updatedBillIndex >= 0) {
            state.bills[updatedBillIndex] = {
              ...response,
              beneficiaryName: state.bills[updatedBillIndex].beneficiaryName,
            };
          } else {
            state.bills.push(response);
          }
        },
      },
      UPDATE_BILL: {
        action: (ctx, payload) => {
          return {
            method: 'PATCH',
            interceptor: paymentsRequest,
            url: `/bills/${ctx.rootGetters.selectedBill.id}`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.selectedBill = response;
          const updatedBillIndex = state.bills.findIndex(
            (bill) => bill.id === response.id,
          );
          if (updatedBillIndex >= 0) {
            state.bills[updatedBillIndex] = response;
          } else {
            state.bills.push(response);
          }
        },
      },
      GET_ADVERTISED_FEES: {
        action: (ctx) => {
          return {
            method: 'get',
            interceptor: paymentsRequest,
            url: `/fees/latest`,
          };
        },
        mutation: (state, { response }) => {
          state.advertisedRates = response;
        },
      },
      GET_FEES_SCHEDULE: {
        action: (ctx, payload) => {
          return {
            method: 'get',
            interceptor: paymentsRequest,
            url: `/fees/bnpl-schedule`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.repaymentSchedule = response;
        },
      },
      GET_TRANSACTIONS_BY_BILL_ID: {
        action: (ctx, payload) => {
          return {
            method: 'get',
            interceptor: paymentsRequest,
            url: `/bills/${payload.billId}/scheduled-transactions`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.scheduledTransactions = response;
        },
      },
      GET_TRANSACTIONS_WITH_FILTERS: {
        action: (ctx, payload) => {
          const { repaymentsType } = payload;
          const url =
            repaymentsType === 'upcoming'
              ? `/businesses/${payload.businessId}/repayment-schedule`
              : `/businesses/${payload.businessId}/repayment-history`;
          return {
            method: 'get',
            url,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.filteredRepayments = response;
        },
      },
      GET_ALL_TRANSACTIONS_BY_BUSINESS_ID: {
        action: (ctx, payload) => {
          return {
            method: 'get',
            interceptor: paymentsRequest,
            url: `/business/${payload.businessId}/scheduled-transactions`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.allRepayments = response.transactions;
        },
      },
      GET_TRANSACTIONS_SUMMARY: {
        action: (ctx, payload) => {
          return {
            method: 'get',
            url: `/businesses/${payload.businessId}/repayment-summary`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.transactionsSummary = response;
        },
      },
      SIGN_BNPL_CONTRACT_FORM: {
        action: (ctx, payload) => ({
          method: 'patch',
          interceptor: paymentsRequest,
          url: `/bills/${payload.billId}/contract/resume-signing-session`,
          params: { returnURL: payload.returnURL.split('?')[0] },
          options: { timeout: 15000 },
          returnResponse: true,
        }),
      },
      GET_BNPL_CONTRACT: {
        action: (ctx, payload) => ({
          method: 'get',
          interceptor: paymentsRequest,
          url: `/bills/${payload.billId}/contract/`,
          options: { timeout: 15000 },
          returnResponse: true,
        }),
      },
      GET_BILL_PAYMENT_TRANSACTIONS: {
        action: (ctx, payload) => ({
          method: 'get',
          interceptor: paymentsRequest,
          url: `/bills/${payload.billId}/payment-transactions`,
          options: { timeout: 15000 },
          returnResponse: true,
        }),
      },
      // This will be removed when V0 Beneficiaries are deprecated
      GET_BILL_BENEFICIARIES: {
        action: (ctx) => {
          return {
            method: 'get',
            interceptor: paymentsRequest,
            url: `/business/${ctx.rootGetters.user.businessId}/beneficiaries`,
          };
        },
        mutation: (state, { response }) => {
          state.beneficiaries = response;
        },
      },
      GET_BENEFICIARIES: {
        action: (ctx, params) => {
          return {
            method: 'get',
            interceptor: beneficiaryRequest,
            url: '/v2/beneficiaries',
            params,
            returnResponse: true,
          };
        },
        mutation: (state, { response }) => {
          const sorted = response.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
          );
          state.beneficiaries = sorted;
        },
      },
      CREATE_BENEFICIARY: {
        action: (ctx, payload) => {
          return {
            method: 'post',
            interceptor: paymentsRequest,
            url: '/v2/beneficiaries',
            params: payload,
            returnResponse: true,
          };
        },
      },
      UPDATE_V2_BENEFICIARY: {
        action: (ctx, payload) => {
          const { beneficiaryId, ...params } = payload;
          return {
            method: 'put',
            interceptor: paymentsRequest,
            url: `/v2/beneficiaries/${beneficiaryId}`,
            params,
            returnResponse: true,
          };
        },
      },
      GET_V2_BENEFICIARY: {
        action: (ctx, payload) => {
          const { beneficiaryV2Id } = payload;
          return {
            method: 'get',
            interceptor: beneficiaryRequest,
            url: `/v2/beneficiaries/${beneficiaryV2Id}`,
            returnResponse: true,
          };
        },
        mutation: (state, { response }) => {
          state.v2Beneficiaries[response.id] = response;
        },
      },
      GET_BUSINESS_CAPACITY: {
        action: (ctx) => {
          return {
            method: 'get',
            url: `/capacity/${ctx.rootGetters.user.businessId}`,
            returnResponse: true,
          };
        },
        mutation: (state, response) => {
          state.businessCapacityResponseCode = response.responseCode;
          state.businessCapacity = response.response;
        },
      },
      GET_BENEFICIARY_BY_ID: {
        action: (ctx, payload) => ({
          method: 'get',
          interceptor: paymentsRequest,
          url: `/beneficiaries/${payload.beneficiaryId}`,
          returnResponse: true,
        }),
        mutation: (state, response) => {
          state.selectedBillBeneficiary = response.response;
        },
      },
      UPDATE_BENEFICIARY_BY_BILL_ID: {
        action: (ctx, payload) => ({
          method: 'put',
          interceptor: paymentsRequest,
          url: `/beneficiary/${payload.billId}`,
          params: payload,
          returnResponse: true,
        }),
        mutation: (state, response) => {
          state.selectedBillBeneficiary = response.response;
        },
      },
      GET_BANK_DETAILS_FIELDS: {
        action: (ctx, payload) => ({
          method: 'get',
          interceptor: beneficiaryRequest,
          url: '/payment-scheme',
          params: payload,
          returnResponse: true,
        }),
      },
    },
    {
      actions: {
        UPDATE_ALL_PAYMENTS_BIZ_PROFILE_STEPS: ({ state, dispatch }) => {
          Object.keys(state.businessProfileSteps).forEach((step) =>
            dispatch('UPDATE_PAYMENTS_BIZ_PROFILE_STEP', step),
          );
        },
        UPDATE_ALL_PAYMENTS_ONBOARDING_STEPS: ({ state, dispatch }) => {
          Object.keys(state.paymentsOnboardingSteps).forEach((step) =>
            dispatch('UPDATE_PAYMENTS_ONBOARDING_STEP', step),
          );
        },
        UPDATE_PAYMENTS_ONBOARDING_STEP: (
          { state, commit, rootGetters },
          step,
        ) => {
          function findCompleted(currentStep) {
            switch (currentStep) {
              case PAYMENTS_ROUTE_NAMES.SIGN_UP:
                return true; // TODO: is sign up step complete?
              case PAYMENTS_ROUTE_NAMES.USER_PROFILE:
                return isUserProfileStepComplete(rootGetters.user);
              case PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS:
                return isOnboardingBusinessDetailsComplete(
                  rootGetters.business,
                );
              case PAYMENTS_ROUTE_NAMES.ADDRESS:
                return isRegisteredAddressComplete(rootGetters.business);
              default:
                return false;
            }
          }
          const updatedStep = {
            ...state.paymentsOnboardingSteps[step],
            isComplete: findCompleted(step),
          };

          commit('UPDATE_PAYMENTS_ONBOARDING_STEP', updatedStep);
        },
        UPDATE_PAYMENTS_BIZ_PROFILE_STEP: (
          { state, commit, getters, rootGetters },
          step,
        ) => {
          function findCompleted(currentStep) {
            switch (currentStep) {
              case PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD:
                return rootGetters.isBusinessProfileComplete;
              case PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT:
                return (
                  rootGetters.hasActiveSalesAccounts &&
                  !rootGetters.userHasActiveBillsAdvanceAndDisconnectedSalesAccount
                );
              case PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING:
                return (
                  (getters.bankingAccountsConnected &&
                    !rootGetters.businessOnPlaidBypass) ||
                  (getters.isUploadBankStatementsStepComplete &&
                    rootGetters.businessOnPlaidBypass)
                );
              case PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS:
                return getters.isBusinessDetailsStepComplete;
              case PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS:
                if (rootGetters.isPersonaEnabled) {
                  return getters.allOwnersCompleted && getters.hasSignatory;
                }

                return rootGetters.isBusinessOwnersStepComplete;
              case PAYMENTS_ROUTE_NAMES.PROFILE_FINANCIAL_DETAILS: {
                if (!rootGetters.debtObligationsRequired) {
                  return true;
                }

                return false;
              }
              default:
                return false;
            }
          }
          function findDisabled(currentStep) {
            if (
              !rootGetters.isPersonaEnabled ||
              currentStep === PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD ||
              currentStep === PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT ||
              currentStep === PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS ||
              findCompleted(currentStep)
            ) {
              return false;
            }
            const stepsList = Object.keys(state.businessProfileSteps);
            const currentStepIndex = stepsList.indexOf(currentStep);
            return !findCompleted(stepsList[currentStepIndex - 1]);
          }

          const updatedStep = {
            ...state.businessProfileSteps[step],
            isComplete: findCompleted(step),
            isDisabled: findDisabled(step),
          };

          commit('UPDATE_PAYMENTS_BIZ_PROFILE_STEP', updatedStep);
        },
        UPDATE_PAYMENTS_STEP: (
          { state, commit, getters, rootGetters },
          step,
        ) => {
          // action to update substeps (getters.steps) within the payments-module
          function findCompleted(currentStep) {
            switch (currentStep) {
              case PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE:
                return isOwnersStepOneComplete(
                  getters.allBusinessProfiles,
                  rootGetters.uploads,
                );
              case PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_TWO:
                return !findIncompleteOwner(getters.allBusinessProfiles);
              case PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST:
                return (
                  isOwnersListStepComplete(
                    rootGetters.user,
                    rootGetters.authorizedSignatory,
                  ) && isBusinessFormationDocsStepComplete(rootGetters)
                );
              default:
                return false;
            }
          }
          const updatedStep = {
            ...state.steps[step],
            isComplete: findCompleted(step),
          };

          commit('UPDATE_PAYMENTS_STEP', updatedStep);
        },
        RESET_SELECTED_BILL: ({ commit }) => {
          commit('RESET_SELECTED_BILL');
        },
        UPDATE_SELECTED_BILL: ({ commit }, updatedBill) => {
          commit('UPDATE_SELECTED_BILL', updatedBill);
        },
        IS_RECEIPT_FLOW: ({ commit }, value) => {
          commit('IS_RECEIPT_FLOW', value);
        },
        RESET_FILTERED_REPAYMENTS: ({ commit }) => {
          commit('RESET_FILTERED_REPAYMENTS');
        },
      },
      mutations: {
        UPDATE_PAYMENTS_BIZ_PROFILE_STEP: (state, updatedStep) => {
          state.businessProfileSteps[updatedStep.name] = updatedStep;
        },
        UPDATE_PAYMENTS_STEP: (state, updatedStep) => {
          state.steps[updatedStep.name] = updatedStep;
        },
        UPDATE_PAYMENTS_ONBOARDING_STEP: (state, updatedStep) => {
          state.paymentsOnboardingSteps[updatedStep.name] = updatedStep;
        },
        RESET_SELECTED_BILL: (state) => {
          state.selectedBill = {};
          state.selectedBillBeneficiary = {};
        },
        UPDATE_SELECTED_BILL: (state, updatedBill) => {
          state.selectedBill = updatedBill;
        },
        IS_RECEIPT_FLOW: (state, value) => {
          state.isReceiptFlow = value;
        },
        RESET_FILTERED_REPAYMENTS: (state) => {
          state.filteredRepayments = {};
        },
        ADD_BENEFICIARY_FORM_FIELD: (state, payload) => {
          state.beneficiaryFormInfo = {
            ...state.beneficiaryFormInfo,
            ...payload,
          };
        },
        RESET_BENEFICIARY_FORM_FIELD: (state) => {
          state.beneficiaryFormInfo = {};
        },
        EMAIL_VERIFIED: (state) => {
          state.emailVerified = true;
        },
      },
    },
  ),
};
