import { buildApiActions } from '@/utils/vuex-api-utils';
import {
  startOfDay,
  parseISO,
  isFuture,
  addDays,
  isAfter,
  endOfDay,
} from 'date-fns';

function get30DayChunks(startDate, endDate) {
  let start = startOfDay(parseISO(startDate));
  const end = endOfDay(parseISO(endDate));
  const chunks = [];

  while (start < end) {
    const updatedEndDate = addDays(start, 30);

    chunks.push({
      startDate: start,
      endDate: isAfter(updatedEndDate, end) ? end : updatedEndDate,
    });
    start = addDays(start, 30);
  }

  return chunks;
}

function getIndexOfTodaysDate(chunks) {
  let indexOfTodaysDate;
  for (let i = 0; i < chunks.length; i++) {
    if (new Date() >= chunks[i].startDate && new Date() <= chunks[i].endDate) {
      indexOfTodaysDate = i;
      break;
    }
  }
  return indexOfTodaysDate;
}

function calculateChunksAndIndex(rootGetters) {
  const startDate =
    rootGetters.businessCapacity?.approvedAt ??
    rootGetters.businessCapacity?.createdAt;
  const expiresAt = rootGetters.businessCapacity?.expiresAt;
  const chunks = get30DayChunks(startDate, expiresAt);
  const indexOfTodaysDate = getIndexOfTodaysDate(chunks);

  return { chunks, indexOfTodaysDate };
}

export default {
  state: {
    debtObligations: {},
  },
  getters: {
    businessDebtObligations: (state) => state.debtObligations,
    debtObligationsRequired: (_state, getters, _rootState, rootGetters) => {
      const hasUploadedDebtObligations =
        Object.keys(getters.businessDebtObligations).length > 0;

      const latestCapacityCreatedDate = startOfDay(
        parseISO(rootGetters.businessCapacity?.createdAt),
      );

      const latestDebtObligationDate = startOfDay(
        parseISO(getters.businessDebtObligations?.created_at),
      );

      return (
        !hasUploadedDebtObligations ||
        (rootGetters.businessCapacityGenerated &&
          (latestCapacityCreatedDate > latestDebtObligationDate ||
            !getters.debtObligationsUploadedInCurrent30DayChunk))
      );
    },
    currentDebtObligationDateChunk: (
      _state,
      getters,
      _rootState,
      rootGetters,
    ) => {
      const { chunks, indexOfTodaysDate } =
        calculateChunksAndIndex(rootGetters);
      return chunks[indexOfTodaysDate];
    },
    debtObligationsUploadedInCurrent30DayChunk: (
      _state,
      getters,
      _rootState,
      rootGetters,
    ) => {
      const { chunks, indexOfTodaysDate } =
        calculateChunksAndIndex(rootGetters);

      return (
        startOfDay(parseISO(getters.businessDebtObligations.created_at)) >=
        chunks[indexOfTodaysDate]?.startDate
      );
    },
    areDebtObligationNotificationsSilenced: (_state, getters) =>
      isFuture(
        startOfDay(
          parseISO(getters.businessDebtObligations?.delay_action_until),
        ),
      ),
    shouldShowDebtObligationNotifications: (_state, getters) =>
      !getters.areDebtObligationNotificationsSilenced &&
      getters.debtObligationsRequired,
  },
  ...buildApiActions({
    GET_DEBT_OBLIGATIONS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/businesses/${ctx.rootGetters.businessId}/debt-obligations/latest`,
      }),
      mutation: (state, { response }) => {
        state.debtObligations = response;
      },
    },
    SAVE_DEBT_OBLIGATIONS: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/businesses/${ctx.rootGetters.businessId}/debt-obligations`,
        params: payload,
      }),
      mutation: (state, { response }) => {
        state.debtObligations = response;
      },
    },
  }),
};
