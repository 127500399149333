<template>
  <footer :class="$style['wayfinding-footer']">
    <p :class="$style['wayfinding-footer__copy']">
      © {{ currentYear }} Clearco
    </p>
    <div :class="$style['wayfinding-footer__links']">
      <a
        v-for="link in links"
        :href="addLocaleParam(clearbancURL + link.url)"
        :class="$style['wayfinding-footer__links__item']"
        target="_blank"
      >
        {{ link.title }}
      </a>
    </div>
  </footer>
</template>

<script>
import addLocaleParam from '@/utils/add-locale-param';

export default {
  data() {
    return {
      links: [
        { title: this.$t('common.termsOfService'), url: '/terms' },
        { title: this.$t('common.privacyPolicy'), url: '/privacy' },
        { title: this.$t('common.cookiePolicy'), url: '/cookie-policy' },
      ],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    clearbancURL() {
      return process.env.CLEARCOM_URL;
    },
  },
  methods: {
    addLocaleParam,
  },
};
</script>

<style lang="less" module>
.wayfinding-footer {
  min-width: 320px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  text-align: center;
  padding: 30px;
  font-family: @gerstner-font;
  font-size: @font-size-14;
}

.wayfinding-footer__copy {
  margin: 0;
  color: @color-grey-300;
  white-space: nowrap;
  display: inline;
}

.wayfinding-footer__links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wayfinding-footer__links__item {
  color: @color-grey-300;
  text-decoration: underline;
}

@media (min-width: 600px) {
  .wayfinding-footer {
    justify-content: space-between;
    flex-direction: row;
    border: 0;
  }

  .wayfinding-footer__links {
    flex-direction: row;
  }

  .wayfinding-footer__links__item + .wayfinding-footer__links__item {
    margin-left: 20px;
  }
}
</style>
