<template>
  <div>
    <UiMsg v-if="errorMessage" type="error">
      {{ errorMessage }}
    </UiMsg>
    <TileGroupOwnersList
      ref="form"
      :event-tracking-fields="commonTrackingFields"
      @validationSuccess="handleValidationSuccess"
      @editOwner="handleEditOwnerClick"
      @addOwner="handleAddOwnerClick"
      @showError="handleError"
    >
      <template #cta>
        <ButtonLinkContainer
          :text="ctaLangKey ? $t(ctaLangKey) : $t('common.buttons.next')"
          :class="buttonClasses"
          @nextClick="handleNextStepClick"
        />
      </template>
    </TileGroupOwnersList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import { mapStateModuleGetters } from '@/utils/state-modules';
import { TileGroupOwnersList, ButtonLinkContainer } from '@/components';
import UiMsg from '@/components/UiMsg';

export default {
  components: {
    TileGroupOwnersList,
    ButtonLinkContainer,
    UiMsg,
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  props: {
    theme: { type: String, default: 'black' },
    ctaLangKey: { type: String, default: '' },
  },
  computed: {
    ...mapGetters(['user', 'business', 'productSegmentLabel']),
    ...mapStateModuleGetters(['steps', 'experimentName']),
    currentRoute() {
      return this.$route.name;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.currentRoute,
        experiment: this.experimentName,
      };
    },
    buttonClasses() {
      return {
        [this.$style[`theme--${this.theme}`]]: !!this.theme,
      };
    },
  },
  methods: {
    async handleNextStepClick() {
      this.showError = false;
      this.$refs.form.validateForm();
    },
    async handleValidationSuccess() {
      analytics.track('fe_self_serve_stage_complete', {
        ...this.commonTrackingFields,
        businessId: this.business.id,
        userId: this.user.id,
        stage: this.currentRoute,
      });
      this.$router
        .push({
          name: this.steps[this.currentRoute].nextRoute.name,
        })
        .catch(() => {});
    },
    async handleEditOwnerClick(owner) {
      this.$router
        .push({
          name: this.steps[this.currentRoute]?.redirectRoute.name,
          params: { id: owner.id },
        })
        .catch(() => {});
    },
    async handleAddOwnerClick(id) {
      this.$router
        .push({
          name: this.steps[this.currentRoute]?.redirectRoute.name,
          params: { id },
        })
        .catch(() => {});
    },
    handleError(errorMessage) {
      this.errorMessage = errorMessage;
    },
  },
};
</script>

<style lang="less" module>
.theme--purple {
  button {
    background-color: @color-cc-purple-200;
    &:hover {
      background-color: @color-cc-purple-200 !important;
    }
  }
}
</style>
