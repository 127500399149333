<template>
  <Modal
    v-model:visible="visible"
    :modal="true"
    :draggable="false"
    append-to="self"
    @hide="$emit('hide')"
  >
    <template #header>
      <div :class="$style['large-icon']">
        <slot />
      </div>
    </template>
    <div :class="$style['modal-content']">
      <div :class="$style['title-container']">
        <h1>{{ title }}</h1>
      </div>
      <div :class="$style['subtitle-container']">
        <p>{{ subtitle }}</p>
      </div>
      <div :class="$style['btn-container']">
        <DSButton :label="buttonLabel" @click="$emit('submitModal')" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    Modal,
    DSButton,
  },
  props: {
    visible: Boolean,
    buttonLabel: String,
    title: String,
    subtitle: String,
  },
};
</script>

<style lang="less" module>
.large-icon {
  margin-top: 10px;
  svg {
    height: 100px;
    width: 100%;
    display: inline-block;
  }
}
.btn-container {
  text-align: center;
  margin: 0;
}
.modal-content {
  max-width: 700px;
  text-align: center;
  margin: 10px;
}
.title-container {
  margin: 0;
  h1 {
    margin: 0;
  }
}
.subtitle-container {
  margin: 0;
  p {
    margin: 16px 0 24px 0;
  }
}
</style>
