<template>
  <div
    :class="[$style.wrapper, isContentDisabled && $style['disable-content']]"
  >
    <div :class="$style.search">
      <InputText
        v-model="searchQuery"
        :class="$style.search__input"
        placeholder="Find your bank"
        @input="getSearchResults"
      />
      <i class="pi pi-search" :class="$style.search__icon" />
    </div>

    <LoadingScreen v-if="isLoading" />

    <div v-if="noSearchResults" :class="$style['no-results']">
      No banks found for <span>{{ searchQuery }}</span>
    </div>

    <div v-if="!isLoading" :class="$style.banks">
      <BankTile
        v-for="bank in bankList"
        :class="$style.banks__item"
        :bank-logo="bank.logo"
        :bank-name="bank.name"
        @click="checkIfBankIsOnWhitelist(bank)"
      />
    </div>
  </div>

  <BankingRoutingModal
    :visible="isRoutingNumberModalVisible"
    @close="closeRoutingNumberModal"
    @routingNumber="checkIfUsersBankIsOnWhitelist"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import InputText from '@clearbanc/clear-components/inputtext';
import LoadingScreen from '@/components/LoadingScreen';
import { PLAID_DEFAULT_BANKS } from '@/data/plaid-default-banks';
import BankingRoutingModal from '@/components/BankingRoutingModal';
import BankTile from '@/components/BankTile';

export default {
  emits: ['open-plaid-modal', 'show-add-manual-bank-account'],
  components: {
    InputText,
    LoadingScreen,
    BankingRoutingModal,
    BankTile,
  },
  data() {
    return {
      isLoading: false,
      isContentDisabled: false,
      searchQuery: '',
      timeout: null,
      banks: [],
      defaultBanks: PLAID_DEFAULT_BANKS,
      isRoutingNumberModalVisible: false,
      institutionThatTriggeredRoutingModal: null,
    };
  },
  computed: {
    ...mapGetters(['whitelistRoutingNumbers']),
    bankList() {
      if (this.searchQuery) {
        return this.banks;
      }

      return this.defaultBanks;
    },
    noSearchResults() {
      return !this.isLoading && !this.banks.length && this.searchQuery;
    },
  },
  methods: {
    getSearchResults() {
      this.isLoading = true;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(async () => {
        if (this.searchQuery) {
          const response = await this.$store.dispatchApiAction(
            'POST_PLAID_PROXY',
            {
              method: 'institutionsSearch',
              query: this.searchQuery,
              options: {
                include_optional_metadata: true,
              },
            },
          );

          if (response?.institutions?.length) {
            this.banks = response.institutions;
          } else {
            this.banks = [];
          }
        }

        this.isLoading = false;
      }, 500);
    },
    async checkIfBankIsOnWhitelist(bank) {
      this.isContentDisabled = true;
      const { institution } = await this.$store.dispatchApiAction(
        'POST_PLAID_PROXY',
        {
          method: 'institutionsGetById',
          institution_id: bank.institution_id,
        },
      );
      this.clickedBankDetails = institution;

      if (
        this.haveCommonElements(
          institution.routing_numbers,
          this.whitelistRoutingNumbers,
        )
      ) {
        this.isRoutingNumberModalVisible = true;
        this.institutionThatTriggeredRoutingModal = institution;
        return;
      }

      this.openPlaidModal(institution?.routing_numbers[0]);
    },
    haveCommonElements(array1, array2) {
      const set1 = new Set(array1);

      for (const element of array2) {
        if (set1.has(element)) {
          return true;
        }
      }

      return false;
    },
    openPlaidModal(routingNumber) {
      this.$emit('open-plaid-modal', routingNumber);
      this.isContentDisabled = false;
    },
    checkIfUsersBankIsOnWhitelist(routingNumber) {
      if (this.whitelistRoutingNumbers.includes(routingNumber)) {
        this.$emit('show-add-manual-bank-account', routingNumber);
        this.isContentDisabled = false;
        return;
      }

      if (this.institutionThatTriggeredRoutingModal) {
        this.openPlaidModal(
          this.institutionThatTriggeredRoutingModal?.routing_numbers[0],
        );
        this.institutionThatTriggeredRoutingModal = null;
      } else {
        this.openPlaidModal();
      }
    },
    closeRoutingNumberModal() {
      this.isContentDisabled = false;
      this.isRoutingNumberModalVisible = false;
    },
  },
};
</script>

<style lang="less" module>
.disable-content {
  pointer-events: none;
}

.wrapper {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 800px) {
  .wrapper {
    margin-bottom: 60px;
  }
}

.search {
  position: relative;
  margin-bottom: 24px;
}

input.search__input {
  padding-left: 44px;
}

.search__icon {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.search__icon::before {
  color: #303032;
}

.no-results {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-results span {
  font-weight: bold;
  display: inline-block;
  margin-left: 6px;
  background: #e8e8ea;
  padding: 1px 7px;
  border-radius: 2px;
}

.banks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
}

.banks__item {
  all: unset;
  width: calc(50% - 8px);
  margin: 16px 0 0 16px;
  cursor: pointer;
}

.banks__item:focus {
  outline: none;
}

@media (max-width: 799px) {
  .banks__item {
    width: calc(50% - 8px);
  }

  .banks__item:nth-child(2n + 1) {
    margin-left: 0;
  }

  .banks__item:nth-child(-n + 2) {
    margin-top: 0;
  }
}

@media (min-width: 800px) {
  .banks__item {
    width: calc(25% - 12px);
    margin: 16px 0 0 16px;
  }

  .banks__item:nth-child(4n + 1) {
    margin-left: 0;
  }

  .banks__item:nth-child(-n + 4) {
    margin-top: 0;
  }
}
</style>
