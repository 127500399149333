<template>
  <VeeForm
    v-slot="{ values, setValues, setFieldValue }"
    class="c-form"
    :class="$style.form"
    :initial-values="initialValues"
    :validation-schema="schema"
    @submit="onSubmit"
    @invalid-submit="onInvalidSubmit"
  >
    <div class="c-form__row grid grid-cols-12">
      <div class="col-span-6 sm:col-span-12">
        <DropdownGroup
          v-if="showAmerCountryOptionsOnly"
          :options="amerCountriesDropdownData"
          :label="$t('common.incorporationCountry')"
          placeholder="Select"
          name="corpCountry"
          option-label="name"
          data-cy="corp-country"
          append-to="self"
          @change="
            () => {
              updateBusinessParams(values.corpCountry.value, 'corpCountry');
              updateStateOptions(values.corpCountry.value);
              updateCorpTypes(values.corpCountry.value);
              setValues({ ...values, corpState: null, corpType: null });
            }
          "
        />
      </div>
      <div class="col-span-6 sm:col-span-12">
        <DropdownGroup
          name="corpState"
          data-cy="corp-state"
          :label="
            $t('common.incorporationPlaceholder', {
              type: jurisdictionLabel(values.corpCountry?.value),
            })
          "
          :options="states"
          option-label="nameString"
          placeholder="Select"
          append-to="self"
          :filter="true"
          :disabled="!values.corpCountry"
          :reset-filter-on-hide="true"
          @change="updateBusinessParams(values.corpState.value, 'corpState')"
        />
      </div>
    </div>
    <div class="c-form__row">
      <InputTextGroup
        :label="$t('common.registeredBusinessName')"
        name="name"
        @blur="
          (e) => {
            trackBusinessNameChange(e.target.value);
            fixBusinessNameEnding(e.target.value, setFieldValue);
            updateBusinessParams(values.name, 'name');
          }
        "
      />
    </div>
    <div class="c-form__row">
      <DropdownGroup
        :options="corpTypes"
        :label="$t('common.legalBusinessStructure')"
        name="corpType"
        option-label="nameString"
        placeholder="Select"
        append-to="self"
        @change="updateBusinessParams(values.corpType.value, 'corpType')"
      />
    </div>
    <div v-if="values.corpType?.value === 'other_option'" class="c-form__row">
      <InputTextGroup
        :label="$t('common.otherLegalBusinessStructure')"
        name="corpTypeOther"
        @blur="updateBusinessParams(values.corpTypeOther, 'corpTypeOther')"
      />
    </div>
    <div class="c-form__row">
      <FormPhoneInput
        :dropdown-attrs="{
          name: 'countryCallingCode',
          options: countryCallingCodes,
          optionLabel: 'nameString',
          placeholder: 'Select',
          filter: true,
        }"
        :input-attrs="{
          name: 'phone',
          label: 'Business Phone Number',
          onBlur: (e) => {
            updateBusinessParams(
              `+${values.countryCallingCode?.callingCode}${e.target.value}`,
              'phone',
            );
          },
        }"
      />
    </div>
    <div v-if="collectEin" class="c-form__row">
      <InputTextGroup
        type="number"
        name="federalTaxId"
        data-cy="federal-tax-id"
        :label="
          federalTaxIdLabel(values.corpCountry?.value) ||
          federalTaxIdShortLabel(values.corpCountry?.value) ||
          federalTaxIdLabel('US')
        "
        @blur="updateBusinessParams(values.federalTaxId, 'federalTaxId')"
      />
    </div>
    <div class="c-form__controls">
      <DSButton
        class="p-button-link mr-auto"
        data-cy="back-button"
        style="padding-left: 0"
        :class="$style['button-link']"
        @click="handleGoBack"
      >
        <svg
          :class="$style['back-button']"
          v-html="require('@/assets/icons/back-icon.svg?raw')"
        ></svg>
      </DSButton>
      <DSButton
        label="Next"
        :class="$style.button"
        type="submit"
        :loading="isLoading"
      />
    </div>
  </VeeForm>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import { COUNTRY_CODE_WITH_NAMES } from '@/data/country-code-with-names';
import { corpTypesForCountry } from '@/data/corp-types-by-country';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import {
  jurisdictionLabel,
  jurisdictionsOptionsForCountry,
  federalTaxIdLabel,
  federalTaxIdShortLabel,
} from '@/utils/local';
import { getAllCallingCodes } from '@/utils/phone-numbers';

import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import analytics from '@/utils/analytics';
import FormPhoneInput from '@/components/forms/FormPhoneInput';
import DSButton from '@clearbanc/clear-components/button';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import { getParsedMessage } from '@/data/error-messages';
import { isRepetitive, isSequential } from '@/utils/numbers';

export default {
  components: {
    VeeForm,
    DSButton,
    InputTextGroup,
    DropdownGroup,
    FormPhoneInput,
  },
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    collectEin: { type: Boolean, default: false },
    showAmerCountryOptionsOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      showError: false,
      initialValues: {},
      states: [],
      corpTypes: [],
      countryCallingCodes: getAllCallingCodes(),
      attemptedBusinessNameFormat: false,
      schema: object({
        corpCountry: object().nullable().required(this.$t('common.required')),
        corpState: object().nullable().required(this.$t('common.required')),
        name: string()
          .when('corpCountry', (country, schema) => {
            const countriesToValidate = ['US', 'CA'];

            // let user proceed with an incorrect business ending if it's their second attempt
            if (
              countriesToValidate.includes(country?.value) &&
              !this.attemptedBusinessNameFormat
            ) {
              return schema.test(
                'name',
                this.$t('components.formBusinessDetails.includeStructure'),
                (value) => {
                  return this.isValidBusinessEnding(value);
                },
              );
            }

            return schema;
          })
          .required(this.$t('common.required')),
        corpType: object().nullable().required(this.$t('common.required')),
        corpTypeOther: string().when('corpType', (corpType, schema) => {
          if (corpType?.value === 'other_option') {
            return schema.required(this.$t('common.required'));
          }

          return schema;
        }),
        phone: string()
          .nullable()
          .when('countryCallingCode', (countryCallingCode, schema) => {
            // https://www.npmjs.com/package/libphonenumber-js
            if (countryCallingCode) {
              return schema
                .test(
                  'is-valid-phone-number',
                  this.$t('common.phoneNumberNotValid'),
                  (value) => {
                    return (
                      isPossiblePhoneNumber(
                        value || '',
                        countryCallingCode?.value,
                      ) &&
                      isValidPhoneNumber(value || '', countryCallingCode?.value)
                    );
                  },
                )
                .required(this.$t('common.required'));
            }

            return schema.required(this.$t && this.$t('common.required'));
          }),
        federalTaxId: string()
          .nullable()
          .when('corpCountry', (corpCountry, schema) => {
            if (this.collectEin) {
              return schema
                .test(
                  'no-repetitive-sequential-digits',
                  'Please enter a valid EIN. Repetitive digits or all zeros are not allowed',
                  (value) => !isRepetitive(value) && !isSequential(value),
                )
                .test(
                  'no-leading-zeros',
                  'The EIN cannot start with a zero',
                  (value) => value?.[0] !== '0',
                )
                .length(9, 'EIN number must be 9 digits')
                .required(this.$t && this.$t('common.required'));
            }

            return schema;
          }),
      }),
      businessNameStructures: {
        allowedBusinessEndings: [
          'Limited',
          'Incorporated',
          'LLP',
          'L3C',
          'Inc.',
          'Ltd.',
          'Corp.',
          ', LLC',
        ],
        endingsToFix: {
          llc: ', LLC',
          inc: 'Inc.',
          ltd: 'Ltd.',
          corp: 'Corp.',
        },
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['connectedSalesAccounts', 'business']),
    ...mapRequestStatuses({
      updateBusinessRequest: 'UPDATE_BUSINESS',
    }),
    COUNTRY_CODES: () => COUNTRY_CODES,
    amerCountriesDropdownData() {
      const amerCountryOptionsData = [];
      [COUNTRY_CODES.US, COUNTRY_CODES.CA].forEach((countryCode) => {
        amerCountryOptionsData.push({
          value: countryCode,
          name: COUNTRY_CODE_WITH_NAMES[countryCode](),
        });
      });
      return amerCountryOptionsData;
    },
  },
  methods: {
    jurisdictionLabel,
    jurisdictionsOptionsForCountry,
    federalTaxIdLabel,
    federalTaxIdShortLabel,
    corpTypesForCountry,
    parsePhoneNumber,
    async onSubmit(values) {
      this.isLoading = true;
      await this.$store.dispatchApiAction('UPDATE_BUSINESS', {
        phone: `+${values.countryCallingCode.callingCode}${values.phone}`,
        corpCountry: values.corpCountry.value,
        name: values.name,
        corpState: values.corpState.value,
        corpType: values.corpTypeOther
          ? `other - ${values.corpTypeOther}`
          : values.corpType.value,
        federalTaxId: values.federalTaxId,
      });
      this.isLoading = false;

      if (this.updateBusinessRequest.isError) {
        this.$emit(
          'validationFailure',
          getParsedMessage(this.updateBusinessRequest.error, true),
        );
        return;
      }

      this.$emit('validationSuccess');
    },
    onInvalidSubmit(values) {
      if (Object.keys(values.errors).includes('name')) {
        this.attemptedBusinessNameFormat = true;
      }
    },
    isValidBusinessEnding(businessName) {
      if (businessName) {
        return this.businessNameStructures.allowedBusinessEndings.some(
          (suffix) => businessName.toLowerCase().endsWith(suffix.toLowerCase()),
        );
      }

      return false;
    },
    fixBusinessNameEnding(value, setFieldValue) {
      // Edge case to prevent an infinite loop
      if (value.toLowerCase().includes(', llc')) return;

      const splitValue = value.split(' ');
      const lastWord = splitValue.pop().toLowerCase();
      const valueWithoutLastWord = splitValue.join(' ');
      const endingsToFix = this.businessNameStructures.endingsToFix;

      if (Object.keys(endingsToFix).includes(lastWord)) {
        setFieldValue(
          'name',
          lastWord === 'llc'
            ? `${valueWithoutLastWord}${endingsToFix[lastWord]}`
            : `${valueWithoutLastWord} ${endingsToFix[lastWord]}`,
        );
      }
    },
    updateInitialValues() {
      // use business corp country on sales account if no current corp country present
      if (
        !this.business.corpCountry &&
        this.connectedSalesAccounts[0]?.businessAddress?.country
      ) {
        this.updateBusinessParams(
          this.connectedSalesAccounts[0].businessAddress.country,
          'corpCountry',
        );
      }

      const corpCountry = this.amerCountriesDropdownData.find((item) => {
        return item.value === this.business.corpCountry;
      });

      if (corpCountry) {
        this.updateStateOptions(corpCountry.value);
        this.updateCorpTypes(corpCountry.value);
      }

      const corpState = this.states.find((item) => {
        return item.value === this.business.corpState;
      });

      if (this.business.corpType?.includes('other - ')) {
        const corpType = this.corpTypes.find((item) => {
          return item.value === 'other_option';
        });

        this.initialValues.corpType = corpType;
        this.initialValues.corpTypeOther = this.business.corpType.substring(8);
      } else {
        const corpType = this.corpTypes.find((item) => {
          return item.value === this.business.corpType;
        });

        this.initialValues.corpType = corpType;
      }

      const storedBusiness = localStorage.getItem('business');

      if (this.business.phone) {
        const parsedPhoneNumber = this.parsePhoneNumber(
          this.business.phone,
          this.business.addressCountryCode || COUNTRY_CODES.US,
        );
        const callingCode = this.countryCallingCodes.find((item) => {
          return item.value === parsedPhoneNumber.country;
        });

        this.initialValues.countryCallingCode = callingCode;
        this.initialValues.phone = parsedPhoneNumber.nationalNumber;
      } else if (storedBusiness) {
        const parsedStoredBusiness = JSON.parse(storedBusiness);
        const parsedPhoneNumber = this.parsePhoneNumber(
          parsedStoredBusiness.phone,
        );
        const callingCode = this.countryCallingCodes.find((item) => {
          return item.value === parsedPhoneNumber.country;
        });

        this.initialValues.countryCallingCode = callingCode;
        this.initialValues.phone = parsedPhoneNumber.nationalNumber;

        this.$store.commit('UPDATE_BUSINESS_PARAMS', {
          phone: parsedStoredBusiness.phone,
        });
      } else {
        this.initialValues.countryCallingCode = this.countryCallingCodes.find(
          (item) => {
            return item.value === 'US';
          },
        );
      }

      if (this.business.name) {
        this.initialValues.name = this.business.name;
      } else if (
        !this.business.name &&
        this.connectedSalesAccounts[0]?.businessName
      ) {
        // use business name on sales account if no current name present
        this.updateBusinessParams(
          this.connectedSalesAccounts[0].businessName,
          'name',
        );

        this.initialValues.name = this.connectedSalesAccounts[0].businessName;
      }

      this.initialValues.corpCountry = corpCountry;
      this.initialValues.corpState = corpState;
      this.initialValues.federalTaxId = this.business.federalTaxId;
    },
    updateBusinessParams(val, name) {
      this.$store.commit('UPDATE_BUSINESS_PARAMS', {
        [name]: val,
        type: 'ecom',
      });
      if (name === 'corpCountry') {
        this.trackCountryChange(val);
        this.updateBusinessParams(null, 'corpState');
        this.updateBusinessParams(null, 'corpType');
      }
      if (name === 'corpTypeOther') {
        this.otherCorpType = val;
      }
    },
    updateStateOptions(countryCode) {
      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      this.states = this.jurisdictionsOptionsForCountry(countryCode).map(
        (value) => {
          return {
            ...value,
            nameString: value.name(),
          };
        },
      );
    },
    updateCorpTypes(countryCode) {
      // https://github.com/clearbanc/code/pull/17133
      // Our dropdowns are unable to invoke the name methods, we require a string
      this.corpTypes = this.corpTypesForCountry(countryCode).map((value) => {
        return {
          ...value,
          nameString: value.name(),
        };
      });
    },
    trackCountryChange(country) {
      analytics.track('fe_country_changed', {
        ...this.eventTrackingFields,
        country,
        type: 'incorporation country',
      });
    },
    trackBusinessNameChange(val) {
      analytics.track('fe_registered_business_name', {
        ...this.eventTrackingFields,
        name: val,
      });
    },
    async handleGoBack() {
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
  },
  async mounted() {
    if (!this.connectedSalesAccounts?.length) {
      await this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
    }

    this.updateInitialValues();

    analytics.track('fe_country_incorp_default', {
      ...this.eventTrackingFields,
      countryIp: this.business.corpCountry,
    });
  },
};
</script>

<style lang="less" module>
.form {
  max-width: 460px;
  margin: 55px auto 0;
  font-family: 'Montserrat';
  color: @bluegray-text;
}

.button {
  min-width: 186px;
}

.back-button {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: auto auto auto 0;
}

@media (min-width: 500px) {
  .form {
    margin-top: 30px;
  }
}
</style>
