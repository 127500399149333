// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body .footer_hk0eM button.footer__button_dwAxL{margin:0;width:calc(50% - 8px)}body .footer_hk0eM button.footer__button_dwAxL+button.footer__button_dwAxL{margin-left:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_hk0eM",
	"footer__button": "footer__button_dwAxL"
};
export default ___CSS_LOADER_EXPORT___;
