<template>
  <div :class="classes">
    <div v-if="!!infoMsg" :class="$style.info">{{ infoMsg }}</div>
    <fieldset>
      <legend v-if="showLegend">{{ computedEyebrow }}</legend>
      <div v-if="isTypePhoneWithCountry" :class="$style.phone">
        <div :class="$style.countryCode">
          <DropdownGroup
            v-model="selectedCountryCode"
            :class="$style.countryCodeDropdown"
            append-to="self"
            label="country-code"
            v-bind="{
              name: 'callingCode',
              options: countryCallingCodes,
              optionLabel: 'nameString',
              filter: true,
            }"
            sr-only
            @change="changeCountryCode"
          >
            <template #value="slotProps">
              <div v-if="!slotProps.value">{{ slotProps.placeholder }}</div>
              <div v-if="selectedCountryCode" :class="$style.callingCodesValue">
                <div>
                  {{ selectedCountryCode.flag }}
                </div>
                <div :class="$style.callingCodesValue__code">
                  +{{ selectedCountryCode.callingCode }}
                </div>
              </div>
            </template>
            <template #option="slotProps">
              <div :class="$style.callingCodesOptions">
                <div :class="$style.callingCodesOptions__item">
                  {{ slotProps.option.flag }}
                </div>
                <div :class="$style.callingCodesOptions__item">
                  {{ slotProps.option.nameString }}
                </div>
                <div :class="$style.callingCodesOptions__item">
                  +{{ slotProps.option.callingCode }}
                </div>
              </div>
            </template>
          </DropdownGroup>
        </div>
        <input
          ref="input"
          v-model="phoneNumber"
          :class="$style.phoneNumber"
          :type="computedType"
          :name="name"
          :data-cy="kebabCase(name)"
          :placeholder="computedPlaceholder"
          :aria-label="name + ' input'"
          :tabindex="disabled ? '-1' : '0'"
          :disabled="disabled"
          :required="required"
          :autocomplete="autofill"
          @input="inputPhoneNumber"
          @blur="onBlur"
          @focus="onFocus"
        />
      </div>
      <date-selector
        v-if="isTypeDate"
        v-model="dateModel"
        :name="name"
        :placeholder="computedPlaceholder"
        label="Date"
        :disabled="disabled"
        :is-active="isActive"
        :min-date="minDate"
        :max-date="maxDate"
        :show-calendar-from-icon-click="showCalendarFromIconClick"
        :class="$style['date-input']"
        @blur="onBlur"
        @focus="onFocus"
        @calendarOpened="toggleCalendar($event, 'open')"
        @calendarClosed="toggleCalendar($event, 'close')"
      />
      <input
        v-if="
          !isTypeDate &&
          !isTypeMoney &&
          !isTypeTextArea &&
          !isTypePhoneWithCountry
        "
        ref="input"
        :type="computedType"
        :name="name"
        :data-cy="kebabCase(name)"
        :value="modelValue"
        :placeholder="computedPlaceholder"
        :aria-label="name + ' input'"
        :tabindex="disabled ? '-1' : '0'"
        :disabled="disabled"
        :required="required"
        :autocomplete="autofill"
        @blur="onBlur"
        @input="onInput"
        @focus="onFocus"
      />
      <textarea
        v-if="isTypeTextArea"
        ref="textarea"
        :class="$style['text-area']"
        :rows="rows"
        :name="name"
        :data-cy="modelValue"
        :value="modelValue"
        :placeholder="computedPlaceholder"
        :aria-label="name + ' input'"
        :tabindex="disabled ? '-1' : '0'"
        :disabled="disabled"
        :required="required"
        :autocomplete="autofill"
        @blur="onBlur"
        @input="onInput"
        @focus="onFocus"
      />
      <Money
        v-if="isTypeMoney"
        v-bind="moneyMask"
        ref="input"
        v-model="computedValue"
        :model-value="modelValue"
        :name="name"
        :data-cy="modelValue"
        :aria-label="name + ' input'"
        :tabindex="disabled ? '-1' : '0'"
        :disabled="disabled"
        :required="required"
        @blur="onBlur"
        @focus="onFocus"
      />
      <span
        v-if="name === 'urlWithPrefilledSubdomain'"
        :class="$style.subdomain"
        v-html="subdomain"
      />
      <ui-icon
        v-if="type === 'password' || type === 'verifyPassword'"
        :name="isPasswordVisible ? 'visible' : 'hidden'"
        size="20"
        :class="$style['password-icon']"
        @click="togglePasswordVisibility"
      />
      <ui-tooltip
        v-if="tooltipMsg"
        position="left"
        size="large"
        clickable
        rounded
        :text="tooltipMsg"
        :class="$style.tooltip"
        @open="$emit('openTooltip')"
        @close="$emit('closeTooltip')"
      />
    </fieldset>
    <div v-if="shouldShowValidationMessage" :class="errorMessageClasses">
      {{ validationErrorMsg || errorMsg }}
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { Money3Component } from 'v-money3';
import moment from 'moment';
import { isValidPostalCode } from '@/utils/local';
import { inputValidationSetup } from '@/composables/validation';
import { UiIcon, UiTooltip } from 'ui-components';
import DateSelector from '@/components/DateSelector';
import kebabCase from '@/utils/kebabcase';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import { getAllCallingCodes, parsePhoneNumber } from '@/utils/phone-numbers';

const TYPES = [
  'email',
  'integer',
  'password',
  'verifyPassword',
  'phone',
  'phoneWithCountry',
  'postalCode',
  'text',
  'textarea',
  'url',
  'website',
  'date',
  'money',
];
export default {
  emits: [
    'focus',
    'input',
    'onBlur',
    'update:modelValue',
    'openTooltip',
    'closeTooltip',
    'isValid',
  ],
  components: {
    UiIcon,
    UiTooltip,
    DateSelector,
    Money: Money3Component,
    DropdownGroup,
  },
  props: {
    type: {
      type: String,
      validator: (val) => {
        return TYPES.includes(val);
      },
      default: 'text',
    },
    disabled: Boolean,
    required: Boolean,
    name: String,
    label: String,
    error: Boolean,
    errorMsg: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.thisFieldIsRequired');
      },
    },
    infoMsg: String,
    tooltipMsg: String,
    modelValue: {
      type: [String, Number, Date],
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
    rows: Number,
    eyebrow: String,
    autofill: { type: String, default: 'on' },
    subdomain: String,
    validateOnBlur: { type: Boolean, default: true },
    removeSpecialCharacters: { type: Boolean },
    specialNumberRegex: { type: RegExp },
    minDate: { type: String },
    maxDate: { type: String },
    showCalendarFromIconClick: Boolean,
    moneyMask: { type: Object },
  },
  setup(props) {
    const { isValid, isTouched } = inputValidationSetup(props);
    return {
      isValid,
      isTouched,
    };
  },
  data() {
    return {
      isValid: false,
      isTouched: false,
      isPasswordVisible: false,
      validationErrorMsg: '',
      showLegend: false,
      isErrorMsgLong: false,
      eyebrowText: null,
      computedValue: this.modelValue,
      phoneNumber: null,
      selectedCountryCode: null,
      countryCallingCodes: getAllCallingCodes(),
    };
  },
  computed: {
    classes() {
      return {
        [this.$style['input-design']]: true,
        [this.$style.disabled]: this.disabled,
        [this.$style.filled]: !!this.modelValue,
        [this.$style.error]: this.shouldShowValidationMessage,
        [this.$style.active]: this.isActive,
      };
    },
    errorMessageClasses() {
      return {
        [this.$style.message]: true,
        [this.$style['long-message']]: this.isErrorMsgLong,
      };
    },
    computedType() {
      if (this.type === 'password' || this.type === 'verifyPassword') {
        return this.isPasswordVisible ? 'text' : 'password';
      }
      return this.type;
    },
    countryPhoneNumber() {
      const phoneNumberWithPrefix = this.modelValue.startsWith('+')
        ? this.modelValue
        : `+${this.modelValue}`;
      return parsePhoneNumber(phoneNumberWithPrefix, 'US');
    },
    isTypePhoneWithCountry() {
      return this.type === 'phoneWithCountry';
    },
    isTypeDate() {
      return this.type === 'date';
    },
    isTypeMoney() {
      return this.type === 'money';
    },
    isTypeTextArea() {
      return this.type === 'textarea';
    },
    computedPlaceholder() {
      if (this.computedType === 'date') {
        return this.showLegend ? this.placeholder : this.eyebrow;
      }
      return this.showLegend ? '' : this.placeholder;
    },
    computedEyebrow() {
      return this.eyebrow ?? this.placeholder;
    },
    dateModel: {
      get() {
        if (!this.modelValue) return '';
        return moment.parseZone(this.modelValue).format('YYYY-MM-DD');
      },
      set(value) {
        const date = moment.parseZone(value).format('YYYY-MM-DD');
        this.validate(date);
        this.$emit('input', date, this.name);
        this.$emit('update:modelValue', date);
      },
    },
    shouldShowValidationMessage() {
      this.validate();
      return !!(
        this.error ||
        (this.validateOnBlur &&
          this.isTouched &&
          (!this.isValid || (this.required && this.modelValue?.length === 0)))
      );
    },
    isActive() {
      return Boolean(this.showLegend || this.modelValue);
    },
  },
  watch: {
    modelValue(val) {
      if (val) this.showLegend = true;
    },
    computedValue(val) {
      this.$emit('input', val, this.name);
      this.$emit('update:modelValue', val);
    },
    errorMsg() {
      this.validate();
    },
    isTouched() {
      this.validate();
    },
    phoneNumber(newPhoneNumber) {
      this.emitPhoneUpdate(
        this.selectedCountryCode.callingCode,
        newPhoneNumber,
      );
    },
    selectedCountryCode(newCountryCode) {
      this.emitPhoneUpdate(newCountryCode.callingCode, this.phoneNumber);
    },
  },
  mounted() {
    if (!this.required) {
      this.isValid = true;
    }
    if (this.modelValue !== null || this.modelValue !== undefined) {
      this.showLegend = true;
      this.validate();
    }
    if (this.isTypePhoneWithCountry) {
      this.phoneNumber = this.countryPhoneNumber?.nationalNumber;
      this.selectedCountryCode = this.countryPhoneNumber?.countryCode;

      // Current number doesn't contain country code prefix
      if (
        !this.phoneNumber &&
        this.selectedCountryCode &&
        !this.modelValue.startsWith('+')
      ) {
        this.phoneNumber = this.modelValue;

        this.emitPhoneUpdate(
          this.selectedCountryCode.callingCode,
          this.phoneNumber,
        );
      }
    }
  },
  methods: {
    kebabCase,
    inputPhoneNumber(e) {
      this.phoneNumber = e.target.value.replace(/[^0-9]/g, '');
    },
    changeCountryCode() {
      setTimeout(() => this.$refs.input.focus(), 200);
    },
    emitPhoneUpdate(countryCode, newPhoneNumber) {
      const fullPhoneNumber = `+${countryCode ?? ''}${newPhoneNumber ?? ''}`;
      this.$emit('input', fullPhoneNumber, this.name);
      this.$emit('update:modelValue', fullPhoneNumber);
    },
    onInput(e) {
      if (!this.isTouched) {
        this.isTouched = true;
      }
      if (this.type === 'phone') {
        // eslint-disable-next-line no-param-reassign
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
      }
      if (this.removeSpecialCharacters) {
        // eslint-disable-next-line no-param-reassign
        e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
      }
      if (this.isTypeMoney) {
        const val = e.target.value.toString();
        this.validate(val);
        this.$emit('input', val, this.name);
        this.$emit('update:modelValue', val);
      } else {
        this.validate(e.target.value);
        // append subdomain to emitted value if input is for urlWithPrefilledSubdomain
        const valueToEmit =
          e.target.value +
          (this.name === 'urlWithPrefilledSubdomain' ? this.subdomain : '');
        this.$emit('input', valueToEmit, this.name);
        this.$emit('update:modelValue', valueToEmit);
      }
    },
    onBlur(e) {
      this.isTouched = true;
      if (e.target && !e.target.value) {
        this.showLegend = false;
      }
      if (this.validateOnBlur) {
        this.validate();
      }
      if (this.isTypeMoney) {
        this.$emit('onBlur', { name: this.name, value: e.toString() });
      } else {
        this.$emit('onBlur', { name: e.target.name, value: e.target.value });
      }
    },
    onFocus() {
      this.showLegend = true;
      this.$emit('focus');
    },
    toggleCalendar(e, action) {
      if (action === 'open') {
        this.showLegend = true;
      }
      if (action === 'close') {
        this.showLegend = !!e;
      }
    },
    validate(val) {
      const internalModelValue = this.isTypePhoneWithCountry
        ? this.phoneNumber
        : this.modelValue;
      const value = val || internalModelValue;
      if (!this.required && !value) return;
      // empty field validation
      if (this.required) {
        this.isValid = value?.length !== 0;
        this.validationErrorMsg =
          this.errorMsg || this.$t('common.requiredField');
        if (this.isTypeMoney) {
          this.isValid = !!value && value > 0;
          this.validationErrorMsg = this.$t('common.valueShouldBeGreaterThan');
        }
      } else {
        this.isValid = true;
      }
      // email validation
      if (this.type === 'email') {
        /* eslint-disable-next-line no-useless-escape */
        const validEmailRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.required) {
          this.isValid = !!validEmailRegex.test(value);
        } else {
          this.isValid =
            this.modelValue === '' || !!validEmailRegex.test(value);
        }
        this.validationErrorMsg =
          this.errorMsg || this.$t('common.invalidEmail');
      }
      // password validation
      if (this.type === 'verifyPassword') {
        const hasDigit = value.match(/[0-9]/) ? 1 : 0;
        const hasUppercase = value.match(/[A-Z]/) ? 1 : 0;
        const hasLowercase = value.match(/[a-z]/) ? 1 : 0;
        const hasSpecialChar = value.match(/[^0-9A-Za-z]/) ? 1 : 0;
        if (value.length < 8) {
          this.isValid = false;
          this.isErrorMsgLong = false;
          this.validationErrorMsg = this.$t('common.passwordIsTooShort');
        } else if (
          hasDigit + hasUppercase + hasLowercase + hasSpecialChar <
          3
        ) {
          this.isValid = false;
          this.isErrorMsgLong = true;
          this.validationErrorMsg = this.$t('common.passwordMustBeAtLeast');
        } else {
          this.isValid = true;
          this.isErrorMsgLong = false;
          this.validationErrorMsg = null;
        }
      }
      // integer validation
      if (this.type === 'integer') {
        // Allow for a special regex to allow characters other than number
        if (this.specialNumberRegex) {
          this.isValid = !!this.specialNumberRegex.test(value);
        } else {
          const validNumberRegex = /^[0-9]{1,}$/;
          this.isValid = !!validNumberRegex.test(value);
        }
        this.validationErrorMsg = this.$t('common.invalidNumber');
      }
      // postal code validation
      if (
        this.type === 'postalCode' &&
        !isValidPostalCode(value, this.specialNumberRegex)
      ) {
        this.isValid = false;
        this.validationErrorMsg = this.$t('common.address.invalidPostalCode');
      }
      // url validation - ensure input is in https://<domain_name>.<tld>
      if (this.type === 'url') {
        const urlRegex =
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        if (this.required) this.isValid = !!urlRegex.test(value);
        else this.isValid = this.modelValue === '' || !!urlRegex.test(value);
        this.validationErrorMsg = this.$t('common.invalidUrl');
      }
      // website validation - accepts any website format; https://<domain_name>.<tld>, www.<domain_name>.<tld>, <domain_name>.<tld>, etc...
      // When making changes to this regex note that some product segment signup flows use ui-components repo components which has duplicated logic.
      // See repo clearbanc/ui-components ui-input.vue: https://github.com/clearbanc/ui-components/blob/staging/src/components/ui-input.vue#L153.
      if (this.type === 'website') {
        // Regex expression from :  https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
        const websiteRegex =
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        // check for too many w's. risk of 4 consecutive w's being legitimate deemed to be low.
        const tooManyWsRegex = /wwww/;
        if (this.required) {
          this.isValid =
            !!websiteRegex.test(value) && !tooManyWsRegex.test(value);
        } else {
          this.isValid = this.modelValue === '' || !!websiteRegex.test(value);
        }
        this.validationErrorMsg = this.$t('common.invalidWebsite');
        this.$emit('isValid', this.name, this.isValid);
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    $touch() {
      this.isTouched = true;
      this.validate();
    },
  },
};
</script>
<style lang="less" module>
.input-design {
  display: block;
  position: relative;
  font-family: 'Montserrat';
  font-weight: 400;
  color: @color-gray;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  @media only screen and (max-width: 500px) {
    width: 100%;
    margin: auto;
  }
  fieldset {
    display: flex;
    position: relative;
    border-radius: 4px;
    border: 1.5px solid @color-gray;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    legend {
      position: absolute;
      font-size: 11px;
      padding: 0 5px;
      display: inline;
      margin-left: 15px;
      line-height: 10px;
      top: -6px;
      color: @color-black-new;
      background-color: @color-white;
      z-index: 2;
    }
    .subdomain {
      transform: translate(0, -1px);
      color: @cc-light-brown;
      border-left: 1px solid @onboarding-light-gray;
      width: fit-content;
      font-size: 16px;
      height: 38px;
      padding: 10px 16px 9px;
      cursor: default;
    }

    .date-input {
      input {
        padding: 13px 0 @gutter-10 @gutter-20;
      }
    }

    input,
    textarea {
      font-size: 16px;
      font-family: 'Montserrat';
      color: @color-black-new;
      padding: 13px 42px @gutter-10 @gutter-20;
      background-color: transparent;
      border: none;
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      -webkit-appearance: none;
      @media only screen and (max-width: 500px) {
        margin-left: 0;
        padding: 8px 42px @gutter-10 gutter-10;
        min-width: unset;
      }
      ::placeholder {
        font-family: 'Montserrat';
        font-size: @font-size-15;
        color: @color-gray;
      }
      &:focus {
        outline: 0;
      }
      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      // removes default pale blue background for autofill
      &:-webkit-autofill {
        -webkit-background-clip: text;
      }
      // ensures autofilled entries have the correct font
      &:-webkit-autofill::first-line {
        font-family: 'Montserrat';
      }
    }

    .text-area {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      height: unset;
    }
  }
  &.active {
    color: @color-black-new;
    fieldset {
      border-color: @color-black-new;
    }
    legend {
      color: @color-black-new;
    }
    ::-webkit-input-placeholder {
      color: @color-black-new;
    }
  }
  &.error {
    color: @color-red;
    fieldset {
      border-color: @color-red;
    }
    span {
      color: @cc-light-brown;
    }
    legend {
      color: @color-red;
    }
  }
  &.disabled {
    color: @color-gray;
    pointer-events: none;
    cursor: not-allowed;
    fieldset {
      border-color: @onboarding-light-gray;
      background-color: @color-lightgray-100;
    }
  }
  .info {
    font-size: @font-size-10;
    line-height: @font-size-10;
    margin-bottom: 2px;
    font-weight: 400;
    text-align: left;
    color: @color-black-new;
  }
  .message {
    position: absolute;
    padding-top: 3px;
    font-size: @font-size-12;
    margin-left: 2px;
    font-family: 'Montserrat';
    line-height: @font-size-12;
    text-align: left;
  }
  .long-message {
    position: unset;
  }
  .password-msg {
    position: absolute;
    top: 11px;
    right: 15px;
    font-size: 10px;
    cursor: pointer;
  }
  .password-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .tooltip {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .phone {
    position: relative;

    div[class='p-dropdown-trigger'] {
      display: none;
    }
    div[class='p-dropdown-items-wrapper'] {
      width: 350px;
    }
  }
  .countryCode {
    position: absolute;
    left: 0;
    top: 1px;
    &.div[class='.p-dropdown'] {
      border: none;
      background: transparent;
      width: 350px;
    }
  }
  .countryCodeDropdown {
    background: transparent;
    border: none;
    height: 40px;
  }
  input.phoneNumber {
    padding-left: 90px;
    padding-right: 4px;
  }
  .callingCodesOptions {
    display: flex;
    align-items: center;
  }
  .callingCodesOptions__item + .callingCodesOptions__item {
    margin-left: 10px;
  }
  .callingCodesValue {
    display: flex;
    align-items: center;
  }
  .callingCodesValue__code {
    margin: 0 0 0 6px;
  }
}
</style>
