import _ from 'lodash';
import deepDiff from 'deep-diff';

/**
 * Compares two objects of the same type to determine which fields are different
 * @param {*} newObject changed object
 * @param {*} originalObject original object against which you want to compare
 * @returns properties of the new object that are differnt from the old object
 */
export function objectDiff(newObject, originalObject) {
  const diffs = deepDiff(newObject, originalObject, (path, diffKey) => {
    const fieldKey = path.length > 0 ? `.${diffKey}` : diffKey;
    const pathKey = `${path.join('.')}${fieldKey}`;
    const newValue = _.get(newObject, pathKey);
    const oldValue = _.get(originalObject, pathKey);

    if (!newValue && !oldValue) {
      return true;
    }

    if (Array.isArray(newValue) && Array.isArray(oldValue)) {
      return _.isEqual(_.sortBy(newValue), _.sortBy(oldValue));
    }
    return false;
  });

  let changedProps = {};
  if (diffs) {
    changedProps = JSON.parse(
      JSON.stringify(
        diffs.reduce((acc, diff) => {
          _.set(acc, diff.path, _.get(newObject, diff.path));
          return acc;
        }, {}),
      ),
    );
  }
  return { diffs, changedProps };
}
