<template>
  <div
    id="businessProfileContainer"
    :class="$style['business-profile-container']"
  >
    <BusinessProfileDashboard
      v-if="isHome && !isLoading"
      :class="$style['dashboard-container']"
    />

    <UiContainer
      v-else-if="!isLoading"
      :display-clearco-logo="false"
      :class="$style['dashboard-container']"
    >
      <template v-if="title" #title>{{ title }}</template>
      <template v-if="subtitle" #subtitle>
        {{ subtitle }}
        <div v-if="subtitle2">
          {{ subtitle2.title }}
          <a v-if="subtitle2.link" target="_blank" :href="subtitle2.href">{{
            subtitle2.link
          }}</a>
        </div>
      </template>
      <router-view :section="$route.name" theme="purple" />
    </UiContainer>

    <LoadingScreen
      v-else
      :display-clearco-logo="false"
      :class="$style['dashboard-container']"
    />

    <div :class="$style['footer-container']">
      <span>
        Need help? Contact us at
        <a :class="$style.link" href="mailto:support@clear.co" target="_blank">
          support@clear.co
        </a>
      </span>

      <a
        :class="$style.link"
        :href="addLocaleParam(clearbancURL + '/privacy')"
        target="_blank"
      >
        {{ $t('common.privacyPolicy') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UiContainer from '@/components/UiContainer';
import LoadingScreen from '@/components/LoadingScreen';

import { PAYMENTS_ROUTE_NAMES, PAYMENTS_STATE_MODULE } from '@/data/payments';
import addLocaleParam from '@/utils/add-locale-param';
import analytics from '@/utils/analytics';
import {
  GTM_CUSTOM_CONVERSIONS,
  trackGtmConversion,
} from '@/utils/conversion-tracking';
import usePersonaVerification from '@/composables/persona';
import BusinessProfileDashboard from './BusinessProfileDashboard';

export default {
  components: {
    UiContainer,
    BusinessProfileDashboard,
    LoadingScreen,
  },
  setup() {
    return usePersonaVerification();
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters([
      'steps',
      'businessProfileSteps',
      'userIsLoggedIn',
      'isPersonaEnabled',
    ]),
    isHome() {
      return this.$route.name === PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD;
    },
    clearbancURL() {
      return process.env.CLEARCOM_URL;
    },
    title() {
      return this.steps[this.$route.name]?.title?.() || '';
    },
    subtitle() {
      return this.steps[this.$route.name]?.subtitle?.() || '';
    },
    subtitle2() {
      return this.steps[this.$route.name]?.subtitle2?.();
    },
    hasCompletedAllSteps() {
      return Object.values(this.businessProfileSteps).every(
        (step) => step.isComplete === true,
      );
    },
  },
  methods: {
    addLocaleParam,
    async fetchLatestUpdates() {
      this.isLoading = true;
      const updates = [
        this.$store.dispatch('setCurrentStateModule', PAYMENTS_STATE_MODULE),
        this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS'),
        this.$store.dispatchApiAction('FETCH_USER_UPLOADS'),
        this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS'),
        this.$store.dispatchApiAction('FETCH_USER_CONTRACTS'),
        this.$store.dispatchApiAction('GET_BANK_STATEMENTS'),
        this.$store.dispatchApiAction('GET_DEBT_OBLIGATIONS'),
        this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS'),
      ];

      if (this.isPersonaEnabled) {
        updates.push(
          this.$store.dispatchApiAction('GET_PERSONA_VERIFICATIONS'),
        );
      }

      await Promise.allSettled(updates);

      await this.$store.dispatch('UPDATE_ALL_PAYMENTS_BIZ_PROFILE_STEPS');
      this.isLoading = false;
    },
  },
  async mounted() {
    if (this.userIsLoggedIn) {
      await this.fetchLatestUpdates();
    }
  },
  watch: {
    $route() {
      // force fetch updates everytime this route loads
      if (this.isHome) {
        this.fetchLatestUpdates();
      }
    },
    async hasCompletedAllSteps(newValue, oldValue) {
      if (newValue !== oldValue && !!newValue) {
        await analytics.track('fe_bnpl_business_profile_completed');
        trackGtmConversion(
          GTM_CUSTOM_CONVERSIONS.CLEARPAY__BUSINESS_PROFILE_COMPLETE,
        );
      }
    },
  },
};
</script>

<style lang="less" module>
.business-profile-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  .dashboard-container {
    font-family: @gerstner-font;
    padding: 48px;
    background: @color-white;
    border: 1px solid @color-lightgray-300;
    box-sizing: border-box;
    border-radius: 4px;
    flex: 1;
    justify-content: center;
    margin-top: 0;

    @media only screen and (min-width: 960px) {
      min-width: 900px;
    }

    @media screen and (max-width: 800px) {
      background: transparent;
      border: none;
      padding: 0;

      [class^='subtitle'] {
        max-width: 100%;
      }
    }
  }
  .footer-container {
    margin-top: 1.5rem;

    @media only screen and (min-width: 960px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    span {
      display: block;
      margin-bottom: 1.5rem;

      @media only screen and (min-width: 960px) {
        display: inline;
      }
    }

    .link {
      color: @color-info-400;
    }
  }
}
</style>
