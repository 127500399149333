<template>
  <div class="box">
    <svg
      v-if="!isPrimary"
      class="close-icon"
      @click="emitRemove"
      v-html="Close"
    ></svg>
    <VeeForm
      v-slot="{ setValues, values }"
      class="c-form container"
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div class="c-form__row">
        <InputTextGroup
          name="firstName"
          label="First name"
          :disabled="owner.completedPersonaForm"
        />
      </div>
      <div class="c-form__row">
        <InputTextGroup
          name="lastName"
          label="Last name"
          :disabled="owner.completedPersonaForm"
        />
      </div>
      <div class="c-form__row">
        <InputTextGroup
          name="email"
          label="Email"
          :disabled="isPrimary || owner.completedPersonaForm"
        />
      </div>
      <div class="c-form__row">
        <DropdownGroup
          name="jobTitle"
          label="Job title"
          :options="jobTitleOptions"
          option-label="value"
          option-value="value"
          placeholder="Select a job title"
          append-to="self"
          @change="onJobTitleChange($event, values, setValues)"
        />
      </div>
      <div class="c-form__row">
        <CheckboxGroup
          name="jobRoles"
          :options="rolesDropdownOptions(values, setValues)"
          @change="onRoleChange($event, values, setValues)"
        />
      </div>
      <div class="c-form__controls">
        <div v-if="showIdVerificationButton" class="button-wrapper">
          <DSButton
            ref="submit"
            :disabled="openPersonaModalQueued"
            :label="
              isPrimary
                ? 'Save and begin ID verification'
                : 'Save and send link to ID verification'
            "
            class="p-button-secondary p-button-outlined"
            type="submit"
            :loading="isLoading"
          />
          <a @click="emitCancel(values)">Cancel</a>
        </div>
        <div v-else class="button-wrapper">
          <DSButton
            ref="submit"
            label="Save"
            class="p-button-secondary p-button-outlined"
            type="submit"
            :loading="isLoading"
          />
          <a @click="emitCancel(values)">Cancel</a>
        </div>
      </div>
    </VeeForm>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Form as VeeForm } from 'vee-validate';
import { object, string, array } from 'yup';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import CheckboxGroup from '@clearbanc/clear-components/checkboxgroup';
import DSButton from '@clearbanc/clear-components/button';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import usePersonaVerification from '@/composables/persona';
import { PERSONA_TEMPLATE_NAMES, PERSONA_STATUS } from '@/data/persona';
import { JobTitlesByCorpCountry } from '@/data/owner';
import { OWNER_ROLES, isAuthorizedOwner } from '@clearbanc/data-common-types';
import Close from '@/assets/icons/close-black.svg?inline';
import { userChangedTheForm, validEmailRegex } from '@/utils/payments';
import analytics from '@/utils/analytics';
import { normalizeStateCode } from '@/utils/local';
import { getDisplayedErrorMessage } from '../../data/error-messages';

export default {
  components: {
    VeeForm,
    InputTextGroup,
    DSButton,
    CheckboxGroup,
    DropdownGroup,
  },
  props: {
    owner: {
      required: true,
    },
  },
  setup() {
    const { initPersonaClient, openPersonaModal, openPersonaModalQueued } =
      usePersonaVerification();
    return { initPersonaClient, openPersonaModal, openPersonaModalQueued };
  },
  data() {
    return {
      schema: object({
        firstName: string().nullable().required(this.$t('common.required')),
        lastName: string().nullable().required(this.$t('common.required')),
        email: string()
          .matches(validEmailRegex, this.$t('common.invalidEmail'))
          .required(this.$t('common.required')),
        jobTitle: string().nullable().required(this.$t('common.required')),
        jobRoles: array(),
      }),
      OWNER_ROLES,
      PERSONA_STATUS,
      Close,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'business',
      'allOwners',
      'selectedOwner',
      'authorizedSignatory',
    ]),
    ...mapRequestStatuses({
      updateBusinessRequest: 'UPDATE_NON_PRIMARY_USER',
      createBusinessRequest: 'CREATE_NON_PRIMARY_USER',
      updateUserRequest: 'UPDATE_USER',
    }),
    isNewProfile() {
      const owner = this.allOwners.some((e) => e.id === this.selectedOwner.id);
      return !owner;
    },
    isPrimary() {
      return !!this.selectedOwner?.isPrimary;
    },
    signatoryAlreadySelectedByOther() {
      return (
        this.authorizedSignatory &&
        this.authorizedSignatory.id !== this.selectedOwner.id
      );
    },
    jobTitleOptions() {
      return JobTitlesByCorpCountry(this.business.corpCountry);
    },
    hasError() {
      return (
        this.updateBusinessRequest.isError || this.updateUserRequest.isError
      );
    },
    initialValues() {
      return {
        firstName: this.selectedOwner?.firstName || '',
        lastName: this.selectedOwner?.lastName || '',
        email: this.selectedOwner?.email || '',
        jobTitle: this.selectedOwner.jobTitle || '',
        jobRoles: this.selectedOwner.jobRoles || [],
      };
    },
    showIdVerificationButton() {
      if (!this.owner.personaFormStatus) {
        return true;
      }
      return ![PERSONA_STATUS.COMPLETE, PERSONA_STATUS.FAILED].includes(
        this.owner.personaFormStatus,
      );
    },
  },
  methods: {
    onJobTitleChange(evt, values, setValues) {
      const { jobRoles } = values;
      if (!isAuthorizedOwner(evt.value, jobRoles)) {
        setValues({
          ...values,
          jobRoles: jobRoles.filter((role) => role !== OWNER_ROLES.SIGNATORY),
        });
      }
    },
    onRoleChange(evt, values, setValues) {
      const { jobTitle, jobRoles } = values;
      if (
        evt.target.value === OWNER_ROLES.OWNER &&
        !evt.target?.checked &&
        !isAuthorizedOwner(jobTitle, jobRoles)
      ) {
        setValues({
          ...values,
          jobRoles: values.jobRoles.filter(
            (role) => role !== OWNER_ROLES.SIGNATORY,
          ),
        });
      }
    },
    rolesDropdownOptions(values) {
      const { jobTitle, jobRoles } = values;

      const availableRoles = [
        {
          value: OWNER_ROLES.OWNER,
          label: 'Beneficial Owner (25%+ Ownership)',
        },
        { value: OWNER_ROLES.DIRECTOR, label: 'Director/Board Member' },
      ];

      if (isAuthorizedOwner(jobTitle, jobRoles)) {
        availableRoles.push({
          value: OWNER_ROLES.SIGNATORY,
          label: 'Authorized Signatory for Clearco. Only one may be identified',
          attributes: { disabled: this.signatoryAlreadySelectedByOther },
        });
      }
      return availableRoles;
    },
    async successfulPrimarySubmitCb(newOwnerValues) {
      let { address } = newOwnerValues;
      if (address) {
        const { country, state } = address;
        address = {
          ...address,
          state: normalizeStateCode(country, state),
        };
      }
      await this.$store.dispatchApiAction('UPDATE_USER', {
        ...newOwnerValues,
        address,
      });
      await this.$store.dispatchApiAction('FETCH_USER_UPLOADS');

      this.saveRequestSucceededToast();
    },
    async onSubmit(values) {
      const { jobRoles } = values;
      const owner = {
        ...values,
        id: this.selectedOwner.id,
        isPrimary: !!this.selectedOwner?.isPrimary,
      };
      if (!this.checkForEmailError(values.email)) {
        return;
      }

      if (this.isPrimary) {
        await this.savePrimaryOwner(owner);
      } else {
        await this.updateBusinessOwners(owner);
      }

      if (!this.hasError && jobRoles.includes(OWNER_ROLES.SIGNATORY))
        this.$emit('is-signatory');
    },
    async savePrimaryOwner(owner) {
      await this.$store.dispatchApiAction('UPDATE_USER', owner);
      if (this.updateUserRequest.isError) {
        this.saveRequestFailed(this.updateUserRequest);
        return;
      }
      // Owner verification only needs to be performed once
      if (this.owner.completedPersonaForm) {
        this.saveRequestSucceededToast();
      } else {
        this.initPersonaClient({
          template: PERSONA_TEMPLATE_NAMES.INDIVIDUAL,
        });
        this.openPersonaModal(this.successfulPrimarySubmitCb);
      }
    },
    async updateBusinessOwners(updatedOwner) {
      this.isLoading = true;

      if (this.isNewProfile) {
        if (this.updateBusinessRequest.isError) {
          this.isLoading = false;
          return this.saveRequestFailed(this.updateBusinessRequest);
        }

        await this.$store.dispatchApiAction('GET_PERSONA_VERIFICATIONS');
        const newUser = await this.$store.dispatchApiAction(
          'CREATE_NON_PRIMARY_USER',
          updatedOwner,
        );

        if (this.createBusinessRequest.isError) {
          this.isLoading = false;
          return this.saveRequestFailed(this.createBusinessRequest);
        }

        if (newUser) {
          // TODO sc-177347 - remove this newUser.owners condition the rollout is done
          const newUserId =
            newUser.owners?.find((owner) => owner.email === updatedOwner.email)
              ?.id ?? newUser.id;

          await this.sendPersonaInquiryEmail({
            ...updatedOwner,
            id: newUserId,
          });

          this.$toast.add({
            severity: 'success',
            detail: `Profile saved and ID verification will be sent to ${updatedOwner.email}.`,
            life: 4000,
          });
        }
      } else {
        const firstNameChanged =
          this.selectedOwner.firstName !== updatedOwner.firstName;
        const lastNameChanged =
          this.selectedOwner.lastName !== updatedOwner.lastName;
        const emailChanged = this.selectedOwner.email !== updatedOwner.email;

        if (
          (firstNameChanged || lastNameChanged || emailChanged) &&
          !this.owner.completedPersonaForm
        ) {
          this.showVerificationPrompt(updatedOwner, updatedOwner, emailChanged);
        } else {
          await this.$store.dispatchApiAction(
            'UPDATE_NON_PRIMARY_USER',
            updatedOwner,
          );

          if (this.updateBusinessRequest.isError) {
            this.isLoading = false;
            return this.saveRequestFailed(this.updateBusinessRequest);
          }

          this.saveRequestSucceededToast();
        }
      }

      this.$store.dispatch('UNSELECT_OWNER');
      this.$emit('remove-temp-owner');
      this.isLoading = false;
      return null;
    },
    saveRequestFailed(request) {
      const { error } = request;
      this.saveRequestFailedToast(getDisplayedErrorMessage(error, true));
      analytics.track('self_serve_error', { error });
    },
    saveRequestSucceededToast() {
      this.$store.dispatch('UNSELECT_OWNER');
      this.$toast.add({
        severity: 'success',
        detail: 'Profile saved',
        life: 3000,
      });
    },
    saveRequestFailedToast(
      errorMessage = 'Profile could not be saved, please try again',
    ) {
      this.$toast.add({
        severity: 'error',
        detail: errorMessage,
        life: 3000,
      });
    },
    emitRemove() {
      this.$emit('remove-click');
    },
    async emitCancel(values) {
      if (userChangedTheForm(this.initialValues, values))
        this.$emit('cancel-click');
      else {
        await this.$store.dispatch('UNSELECT_OWNER');
      }
    },
    showVerificationPrompt(currentOwner, updatedOwner, emailChanged) {
      this.$confirm.require({
        message:
          'Updating this profile will require us to send a new link to ID verification. Do you want to continue?',
        header: 'ID Verification Needed',
        acceptLabel: 'Save and send new link',
        rejectLabel: 'Discard Changes',
        accept: async () => {
          await this.$store.dispatchApiAction(
            'UPDATE_NON_PRIMARY_USER',
            updatedOwner,
          );

          if (this.updateBusinessRequest.isError) {
            this.saveRequestFailed(this.updateBusinessRequest);
          } else {
            this.$store.dispatch('UNSELECT_OWNER');
            this.$toast.add({
              severity: 'success',
              detail: `Profile saved and ID verification will be sent to ${currentOwner.email}.`,
              life: 4000,
            });
          }

          await this.sendPersonaInquiryEmail(currentOwner, emailChanged);

          this.$store.dispatch('UNSELECT_OWNER');
          this.$emit('remove-temp-owner');
        },
        reject: () => {
          this.$store.dispatch('UNSELECT_OWNER');
          this.$emit('remove-temp-owner');
        },
      });
    },
    async sendPersonaInquiryEmail(ownerDetails, emailChanged) {
      await this.$store.dispatchApiAction('SEND_PERSONA_INQUIRY_EMAIL', {
        entityId: ownerDetails.id,
        entityType: 'owner',
        firstName: ownerDetails.firstName,
        lastName: ownerDetails.lastName,
        email: ownerDetails.email,
        shouldSendEmail: true,
        isEmailUpdated: emailChanged,
      });
    },
    checkForEmailError(newEmail) {
      const businessHasOwnerWithSameEmail = this.allOwners.some(
        (owner) =>
          owner.email === newEmail && owner.id !== this.selectedOwner.id,
      );
      if (businessHasOwnerWithSameEmail) {
        this.saveRequestFailedToast('This email is already in use.');
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.c-form__controls) {
  justify-content: center;
}
:deep(.c-form__row) {
  padding: 0.5rem 0;
}
</style>

<style lang="less" scoped>
.box {
  background: @color-white;
  border: 1px solid #ceced0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  position: relative;
  border-radius: 4px;
  padding: 3rem;

  @media screen and (max-width: 640px) {
    padding: 3rem 1.5rem;
  }
  .close-icon {
    height: 12px;
    width: 12px;
    position: absolute;
    cursor: pointer;
    margin: 1rem;
    top: 0;
    right: 0;
  }
  .button-wrapper {
    display: grid;
    text-align: center;
    row-gap: 15px;

    a {
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
