import { isRepetitive, isSequential } from './numbers';

export function validateSSN(value) {
  if (value.length !== 9) {
    throw new Error('Please enter a valid 9-digit SSN.');
  }

  // Check for last four digits
  if (value.endsWith('0000')) {
    throw new Error(
      'Please enter a valid SSN ‘0000’ on the last 4 digits are not allowed.',
    );
  }

  // Check for invalid area numbers
  const areaNumber = Number(value.slice(0, 3));
  if (
    areaNumber === 0 ||
    areaNumber === 666 ||
    (areaNumber >= 900 && areaNumber <= 999)
  ) {
    throw new Error(
      'Please enter a valid SSN. The first three digits are invalid.',
    );
  }

  if (isRepetitive(value) || isSequential(value)) {
    throw new Error(
      'Please enter a valid SSN. Sequential or repetitive digits are not allowed.',
    );
  }
}
