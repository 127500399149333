import _ from 'lodash';
import storage from 'local-storage-fallback';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import analytics from '@/utils/analytics';

import {
  isCountryWithPostalCode,
  isPassportRequiredForCountry,
  isQualifiedNorthAmericanCountry,
  COUNTRY_CODES,
  COUNTRY_CODE_WITH_NAMES,
} from '@/data/country-code-with-names';
import { buildApiActions } from '@/utils/vuex-api-utils';
import { BUSINESS_CATEGORY_NAME } from '@/data/business-category';
import { PRODUCT_SEGMENT } from '@/data/product-segments';
import { OWNER_ROLES } from '@clearbanc/data-common-types';
import { WAYFINDING_ROUTES } from '@/data/wayfinding';
import { v4 as uuidv4 } from 'uuid';
import { usersApi } from '../../utils/baus-request';
import { diligenceServiceVerifications } from '../../utils/diligence-service';

// TODO (sc-178331): remove this constant and transformers when the "write" user is done
// and we are reading business owners from BAUS
const RENAMED_USER_FIELDS = {
  roles: 'jobRoles',
  phone: 'phoneNumber',
  iviConsentAt: 'agreedToKyc',
  subscribedToMarketingEmails: 'agreedToNewsletter',
};

const transformReadUserFields = (user, preferLegacyValues = false) => {
  const newUserValues = Object.keys(RENAMED_USER_FIELDS).reduce((acc, key) => {
    const newName = RENAMED_USER_FIELDS[key];
    let newValue = preferLegacyValues
      ? user[key] ?? user[newName]
      : user[newName] ?? user[key];

    if (key === 'roles' && !newValue?.length) {
      newValue =
        preferLegacyValues && user[key]?.length
          ? user[key]
          : user[newName] ?? [];
    }

    if (newName === 'agreedToKyc' && preferLegacyValues) {
      // sometimes the monolith returns undefined for iviConsent; which fails the isProfileComplete required fields check
      newValue = !!newValue;
    }
    return {
      ...acc,
      [newName]: newValue,
    };
  }, {});
  return {
    ...user,
    ...newUserValues,
    address: user.address ?? {},
    id: user.legacyOwnerId ?? user.id?.toString(),
  };
};

const transformWriteUserFields = (user, bausIntegration = false) => {
  const immutableFields = [
    'legacyOwnerId',
    'shippingAddress',
    'agreedToTerms',
    'isPrimary',
  ];
  if (bausIntegration || user.isPrimary) {
    immutableFields.push('id');
  }
  if (!user.isPrimary) {
    immutableFields.push('subscribedToMarketingEmails');
    immutableFields.push('shippingAddress');
  }
  if (bausIntegration) {
    if (user.isPrimary) {
      immutableFields.push('email');
    }
    return _.omit(user, [
      ...immutableFields,
      ...Object.keys(RENAMED_USER_FIELDS),
    ]);
  }
  const newUserValues = Object.keys(RENAMED_USER_FIELDS).reduce((acc, key) => {
    const newName = RENAMED_USER_FIELDS[key];
    return {
      ...acc,
      [key]: user[newName] ?? user[key],
    };
  }, {});
  const userToUpdate = {
    ...user,
    ...newUserValues,
    ...(user.id === 'new' && {
      id: uuidv4(),
    }),
  };
  return _.omit(userToUpdate, [
    ...immutableFields,
    ...Object.values(RENAMED_USER_FIELDS),
  ]);
};

const allPropsFilled = (obj, props) =>
  obj && _.every(props, (p) => !_.isNil(obj[p]));

function getProfileCountry(profile) {
  const userCountries = [
    _.get(profile, 'address.country'),
    profile.citizenship,
  ];
  if (userCountries.includes('US')) return 'US';
  if (userCountries.includes('CA')) return 'CA';
  return profile.citizenship || 'US';
}

export function isProfileComplete(profile, getters) {
  if (!allPropsFilled(profile, ['firstName', 'lastName'])) return false;
  if (
    !allPropsFilled(profile, [
      'birthday',
      'phoneNumber',
      'email',
      'address',
      'citizenship',
      'jobTitle',
    ])
  )
    return false;
  const addressRequirements = ['line1', 'city', 'state', 'country'];
  if (isCountryWithPostalCode(profile.address?.country))
    addressRequirements.push('postalCode');
  if (!allPropsFilled(profile.address, addressRequirements)) return false;
  if (['US', 'CA'].includes(profile.citizenship) && !profile.personalTaxId) {
    return false;
  } else if (
    isPassportRequiredForCountry(getProfileCountry(profile)) &&
    !profile.passportNumber &&
    !getters.isSelfServeUser
  ) {
    return false;
  }
  return true;
}

/* ----  Helpers used in mutations below ---- */

/**
 * Updates the user state with the provided data. This has
 * guardrails to ensure the user state is never set to a user
 * that is not a Primary User associated to a Business
 */
function setUserState(state, user) {
  const parsedUserId = Number(user.id);
  if (
    (user.id && Number.isNaN(parsedUserId)) ||
    (state.user.id && state.user.id !== parsedUserId) ||
    user.isPrimary === false
  ) {
    console.warn('Requested to set user state to invalid user');
    return;
  }

  state.user = _.omit(user, ['id', 'business']);
  state.user.id = parsedUserId;
  state.user.isPrimary = true;
  if (!state.user.address) state.user.address = {};
  if (!state.user.shippingAddress) state.user.shippingAddress = {};
}

function setBusinessState(state, business) {
  state.business = business;
  if (!state.business.address) {
    state.business.address = {
      city: state.business.addressCity,
      country: state.business.addressCountryCode,
      line1: state.business.addressStreet1,
      postalCode: state.business.addressPostalCode,
      state: state.business.addressState,
    };
  }
  if (!state.business.shippingAddress) state.business.shippingAddress = {};
  if (!state.business.truliooSearchDetails)
    state.business.truliooSearchDetails = {};
}

function setOwnersState(state, owners) {
  state.owners = (owners ?? []).filter((user) => !user.isPrimary);
}

function authSuccess(state, response) {
  // set analytics tracking
  analytics.identify(response.user.id, {
    productSegment: _.get(response.user.business, 'productSegment.label'),
    email: response.user.email,
    ...response.user?.featureFlags,
  });
  // update Vuex store state
  setUserState(state, response.user);
  // TODO (SC-178331): remove condition when we rollout isBausIntegrationEnabled
  if (response.user.business) {
    setBusinessState(state, response.user.business);
    setOwnersState(state, response.user.business.owners);
  }
  // update storage
  Cookies.set('cb-user-token', response.authToken, {
    sameSite: 'None',
    secure: true,
  });
  storage.setItem('cb-user-id', response.user.id);
  // add user/business ids to Sentry errors
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: response.user.id,
      businessId: response.user.businessId,
      email: response.user.email,
    });
  });
}

export default {
  state: {
    user: {},
    business: {},
    businessTags: {},
  },
  getters: {
    oauthSignup: (state) =>
      !!(state.user.oauthSignup || storage.getItem('oauthSignup')),
    // these ids used by submodules to know what ID to use in URLS
    userIsLoggedIn: (state) => !_.isEmpty(state.user),
    userId: (state) => state.user.id,
    shouldShowLanguageInputs: (state) =>
      state.business.corpCountry === COUNTRY_CODES.DE,
    businessId: (state) => state.business.id,
    businessName: (state) => state.business.name,
    businessAccountManager: (state) => state.businessAccountManager,
    user: (state, getters) => {
      return transformReadUserFields(
        _.cloneDeep(state.user),
        !getters.isBausIntegrationEnabled,
      );
    },
    business: (state, getters) => ({
      ...state.business,
      owners: getters.businessOwners,
    }),
    businessType: (state) => state.business.type,
    businessCategoryId: (state) => state.business.categoryId,
    businessCategoryName: (state) =>
      BUSINESS_CATEGORY_NAME()[state.business.categoryId],
    businessCategorySelected: (state) => state.business.categoryId !== null,
    businessCorpCountry: (state) => state.business.corpCountry,
    businessCorpCountryName: (state) =>
      COUNTRY_CODE_WITH_NAMES[state.business.corpCountry](),
    isKYBinitiated: (state) => state.business.isKYBinitiated,
    isUSBusiness: (state) => state.business.corpCountry === COUNTRY_CODES.US,
    isCABusiness: (state) => state.business.corpCountry === COUNTRY_CODES.CA,
    isAMERBusiness: (state) =>
      [COUNTRY_CODES.US, COUNTRY_CODES.CA].includes(state.business.corpCountry),
    isAUBusiness: (state) => state.business.corpCountry === COUNTRY_CODES.AU,
    isUKBusiness: (state) => state.business.corpCountry === COUNTRY_CODES.GB,
    isPreferredVendorEligible: (state) =>
      !!['US', 'CA'].includes(state.business.corpCountry),
    isAUAccount: (state) => (account) => account.currencyCode === 'AUD',
    isUKAccount: (state) => (account) => account.currencyCode === 'GBP',
    productSegmentLabel(state) {
      const { label } = state.business.productSegment || {};
      return label;
    },
    McaApplicationEntryRoute(state, getters) {
      if (getters.isPostOfferFlowUser && getters.activeAdvanceOffers.length) {
        return getters.navigationPreviewStep?.name;
      }
      return 'self-serve-connect-sales';
    },
    applicationRoute(state, getters) {
      const { productSegment } = state.business || {
        productSegment: {},
      };

      switch (productSegment.label) {
        case PRODUCT_SEGMENT.SELFSERVE:
          return {
            name: getters.McaApplicationEntryRoute,
          };
        case PRODUCT_SEGMENT.CORE:
          return { name: WAYFINDING_ROUTES.APPLICATION };
        default:
          return { name: 'application' };
      }
    },
    isChargeCardUser(state, getters) {
      return getters.productSegmentLabel === PRODUCT_SEGMENT.CHARGE_CARD;
    },
    isSelfServeUser(state, getters) {
      return getters.productSegmentLabel === PRODUCT_SEGMENT.SELFSERVE;
    },
    isCoreUser(state, getters) {
      return getters.productSegmentLabel === PRODUCT_SEGMENT.CORE;
    },
    isGermanBusiness(state, getters) {
      return state.business.corpCountry === COUNTRY_CODES.DE;
    },
    isB2BMerchant: (state, getters) =>
      [PRODUCT_SEGMENT.MRR_FACTORING, PRODUCT_SEGMENT.EXTEND_RUNWAY].includes(
        getters.productSegmentLabel,
      ),
    isIndiegogoMerchant: (state, getters) =>
      [PRODUCT_SEGMENT.INDIEGOGO].includes(getters.productSegmentLabel),
    isClearcashUser: (state) => !!state.user.featureFlags?.clearcash,
    isAmerUser: (state) =>
      !!isQualifiedNorthAmericanCountry(state.business.corpCountry),
    isPostOfferFlowUser: (state, getters) => getters.isSelfServeUser,
    userHasForceBnplFlag: (state) => !!state.user.featureFlags?.forceBnpl,
    isBnplMerchant: (state, getters) =>
      [PRODUCT_SEGMENT.BNPL].includes(getters.productSegmentLabel),
    isExtendRunwayMerchant: (state, getters) =>
      getters.productSegmentLabel === PRODUCT_SEGMENT.EXTEND_RUNWAY,
    isGoCardlessAccountCurrency: (state) => (account) =>
      ['AUD', 'EUR', 'GBP'].includes(account.currencyCode),
    isPlaidConnectSupportedCountry: (state) => {
      return ['CA', 'GB', 'US'].includes(state.business.corpCountry);
    },
    isLegacyClearpay: (state) =>
      Boolean(state.user.featureFlags?.legacyClearPayments),
    isGoCardlessDirectDebitOnlyCountry: (state) => {
      if (process.env.CLEARBANC_ENV !== 'production') {
        return ['NL'].includes(state.business.corpCountry);
      }
      return [
        COUNTRY_CODES.NL,
        COUNTRY_CODES.AU,
        COUNTRY_CODES.DE,
        COUNTRY_CODES.AT,
        COUNTRY_CODES.IE,
        COUNTRY_CODES.BE,
        COUNTRY_CODES.FI,
      ].includes(state.business.corpCountry);
    },
    bestName: (state) => state.user.firstName || 'Friend',
    isAuthorizedSignatory: (_state, getter) =>
      getter.user.id === getter.authorizedSignatory?.id,
    authorizedSignatory: (state, getters) => {
      return getters.allOwners.find((owner) =>
        owner.jobRoles?.includes(OWNER_ROLES.SIGNATORY),
      );
    },
    isUserPropLocked: (state) => (p) => _.includes(state.user.lockedProps, p),
    userHasPropsLocked: (state) => _.get(state.user, 'lockedProps.length'),
    isBusinessPropLocked: (state) => (p) =>
      _.includes(state.business.lockedProps, p),
    businessHasPropsLocked: (state) =>
      _.get(state.business, 'lockedProps.length'),
    truliooBusinessSearchSelected: (state) =>
      Object.keys(_.get(state, 'business.truliooSearchDetails.selected') || {})
        .length > 0,
    activeBusinessTag: (state) => {
      const tags = _.values(state.businessTags);
      // find the tag without a removedAt date, otherwise return {}
      const activeTag = tags.find((tag) => {
        return !tag.removedAt;
      });
      return activeTag;
    },
    offersBlockedByTag: (state) => {
      const offerBlockingTags = ['ICEBOX', 'DEAD', 'HOSTILE', 'HOSTILE_LEGACY'];
      const tags = Object.values(state.businessTags).filter(
        (tag) => !tag.removedAt,
      );
      return (
        tags.filter((tag) => offerBlockingTags.includes(tag.tag)).length > 0
      );
    },
    offersBlockedByTagMessage: (_state, getters) => {
      if (getters.offersBlockedByTag) {
        return `Offers are hidden from customer due to ${getters.activeBusinessTag?.tag} tag.`;
      }
      return undefined;
    },
    businessTagHistory: (state) => _.values(state.businessTags),
    // basic information required to generate the contract
    // (and the business incorporation check)
    basicInfoErrors: (state) => {
      const u = state.user;
      const b = state.business;
      const ba = b.address || {};

      const issues = [];

      if (!u.firstName || !u.lastName) issues.push('User needs full name');
      _.each(
        {
          email: 'email',
          phoneNumber: 'phone number',
        },
        (label, prop) => {
          if (!u[prop]) issues.push(`User missing ${label}`);
        },
      );
      _.each(
        {
          name: 'name',
          phone: 'phone number',
        },
        (label, prop) => {
          if (!b[prop]) issues.push(`Business missing ${label}`);
        },
      );

      const addressComplete = !!(ba.line1 && ba.city && ba.state && ba.country);
      const postalCodeRequireIncomplete = !!(
        isCountryWithPostalCode(ba.country) && !ba.postalCode
      );
      if (!addressComplete || postalCodeRequireIncomplete) {
        issues.push('Business address incomplete');
      }

      if (!b.isCorp) issues.push('Business must be incorporated');
      else if (
        (b.corpCountry === 'US' && !b.federalTaxId) ||
        (b.corpCountry !== 'US' && !b.registrationNumber)
      )
        issues.push('Business must have federal tax id (EIN/BN)');
      return issues;
    },

    // BUSINESS DETAILS ////////////////////////////////////////////////////////////////////////////
    businessDetailsIssues: (state) => {
      const b = state.business;
      const a = b.address || {};
      const s = b.shippingAddress || {};
      const issues = [];
      const required = {
        name: 'business name',
        type: 'business type',
        corpCountry: 'home country',
        corpType: 'legal business structure',
        phone: 'business phone number',
        corpState: 'incorporation state',
      };
      if (b.corpCountry === 'US') {
        required.federalTaxId = 'federal tax id (EIN)';
      } else {
        required.registrationNumber = 'business registration number';
      }
      if (['US', 'CA'].includes(b.corpCountry)) {
        required.corpDate = 'incorporation date';
      }
      _.each(required, (label, prop) => {
        if (!b[prop]) issues.push(`Please provide your ${label}`);
      });

      const addressComplete = !!(a.line1 && a.city && a.state && a.country);
      const postalCodeRequireIncomplete = !!(
        isCountryWithPostalCode(a.country) && !a.postalCode
      );
      if (!addressComplete || postalCodeRequireIncomplete) {
        issues.push('Please complete your business address');
      }

      if (!b.shippingAddressSame) {
        const shippingAddressComplete = !!(
          s.line1 &&
          s.city &&
          s.state &&
          s.country
        );
        const shippingPostalCodeRequireIncomplete = !!(
          isCountryWithPostalCode(s.country) && !s.postalCode
        );
        if (!shippingAddressComplete || shippingPostalCodeRequireIncomplete) {
          issues.push('Please complete your shipping address');
        }
      }
      return issues;
    },
    businessDetailsComplete: (state, getters) =>
      getters.businessDetailsIssues.length === 0,
    corpDetailsComplete: (state) => {
      const requiredFields = ['corpCountry', 'corpType', 'corpState'];
      if (state.business.corpCountry === 'US') {
        requiredFields.push('federalTaxId');
      } else {
        requiredFields.push('registrationNumber');
      }
      if (state.business.corpCountry !== 'NL') {
        requiredFields.push('stateTaxId');
      }
      if (['US', 'CA'].includes(state.business.corpCountry)) {
        requiredFields.push('corpDate');
      }

      return allPropsFilled(state.business, requiredFields);
    },

    businessOwners: (state) => {
      return (state.owners ?? []).map((owner) =>
        transformReadUserFields(_.cloneDeep(owner), true),
      );
    },
    // OWNER PROFILES (INCLUDING PRIMARY USER) /////////////////////////////////////////////////////
    allOwners: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.isPersonaEnabled) {
        return [{ ...getters.user }, ...getters.businessOwners];
      }

      return [
        {
          ...getters.user,
          personaFormStatus: rootGetters.personaInquiryStatus(state.user.id),
          completedPersonaForm: rootGetters.personaOwnerVerification(
            state.user.id,
          ),
        },
        ...getters.businessOwners.map((owner) => ({
          ...owner,
          personaFormStatus: rootGetters.personaInquiryStatus(owner.id),
          completedPersonaForm: rootGetters.personaOwnerVerification(owner.id),
        })),
      ];
    },
    allOwnersCompleted: (state, getters) =>
      getters.allOwners.every((o) => !!o.completedPersonaForm),
    hasSignatory: (state, getters) => {
      return !!getters.authorizedSignatory;
    },
    hasDirector: (state, getters) => {
      return getters.allOwners.some((owner) => {
        return owner.jobRoles.includes(OWNER_ROLES.DIRECTOR);
      });
    },
    isPrimaryUserInfoComplete(state, getters) {
      return (
        isProfileComplete(getters.user, getters) && getters.user.agreedToKyc
      );
    },
    isOwnerInfoComplete: (state, getters) => (ownerId) => {
      const ownerProfile =
        _.find(getters.businessOwners, { id: ownerId }) || {};
      return isProfileComplete(ownerProfile, getters);
    },
    allOwnerInfoComplete: (state, getters) => {
      const allPersons = [getters.user, ...getters.businessOwners];
      const owners = allPersons.filter((person) =>
        (person.jobRoles || []).includes(OWNER_ROLES.OWNER),
      );
      if (owners.length === 0) return false;
      // cannot have more than 4 owners with 25%
      if (owners.length >= 4) return false; // TODO: expose an error somewhere?
      return allPersons.every((person) => isProfileComplete(person, getters));
    },

    // FACT DECLARATION ////////////////////////////////////////////////////////////////////////////
    factDeclarationSigned: (state) => !!state.business.factDeclarationSignedAt,
    fiscalStartDate: (state) => state.business.fiscalStartDate,
    businessOnPlaidBypass: (state, getters, rootState, rootGetters) =>
      state.business.plaidBypass?.bank_id && rootGetters.isBypassPlaidEnabled,
  },
  ...buildApiActions(
    {
      SIGNUP: {
        action: (ctx, payload) => ({
          method: 'post',
          url: '/signup',
          params: { ...payload, language: ctx.rootGetters.selectedLanguage },
        }),
        mutation: (state, { response }) => {
          authSuccess(state, response);
        },
      },
      FEDERATED_SIGNUP: {
        action: (ctx, payload) => ({
          method: 'post',
          url: '/federated-signup',
          params: { ...payload, language: ctx.rootGetters.selectedLanguage },
        }),
        mutation: (state, { response }) => {
          authSuccess(state, response);
        },
      },
      GET_USER: {
        action: (ctx, payload) =>
          ctx.rootGetters.isBausIntegrationEnabled
            ? {
                requestFunc: () =>
                  usersApi.findUserById({
                    userId: payload.userId,
                  }),
                returnResponse: true,
              }
            : {
                method: 'get',
                url: `/users/${payload.userId}`,
                returnResponse: true,
              },
        mutation: (state, { response }) => {
          analytics.identify(response.id);
          setUserState(state, response);
          // TODO (SC-178331): remove condition when we rollout isBausIntegrationEnabled
          if (response.business) {
            setBusinessState(state, response.business);
            setOwnersState(state, response.business.owners);
          }
          // add user/business ids to Sentry errors
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: response.id,
              businessId: response.businessId,
              email: response.email,
            });
          });
        },
      },
      GET_BUSINESS: {
        action: (ctx) => {
          return {
            method: 'get',
            url: `/businesses/${ctx.state.user.businessId}`,
          };
        },
        mutation: (state, { response }) => {
          setBusinessState(state, response);
        },
      },
      GET_OWNERS: {
        action: (ctx) => ({
          requestFunc: () =>
            usersApi.findByAttributes({
              businessId: ctx.state.user.businessId,
            }),
          returnResponse: true,
        }),
        mutation: (state, { response }) => {
          setOwnersState(state, response);
        },
      },
      UPDATE_USER: {
        action: (ctx, payload) =>
          ctx.rootGetters.isBausIntegrationEnabled
            ? {
                requestFunc: () =>
                  usersApi.updateUser({
                    id: ctx.state.user.id,
                    patchUserDto: transformWriteUserFields(payload, true),
                  }),
              }
            : {
                method: 'patch',
                url: `/users/${ctx.state.user.id}`,
                params: transformWriteUserFields(payload),
              },
        mutation: (state, { response }) => {
          setUserState(state, response);
        },
      },
      UPDATE_USER_PROFILE: {
        action: (ctx, payload) =>
          ctx.rootGetters.isBausIntegrationEnabled
            ? {
                requestFunc: () =>
                  usersApi.updateUser({
                    id: ctx.state.user.id,
                    patchUserDto: payload,
                  }),
              }
            : {
                method: 'patch',
                url: `/users/me/profile`,
                params: payload,
              },
        mutation: (state, { response }) => {
          // sc-177347 - "response.business?.userId === response.id" should be removed when rollout multi user
          if (response.isPrimary || response.business?.userId === response.id) {
            setUserState(state, response);
          } else {
            const nonUpdatedOwners = state.owners.filter(
              (owner) => owner.id !== response.id,
            );
            setOwnersState(state, [...nonUpdatedOwners, response]);
          }
        },
      },
      UPDATE_BUSINESS: {
        action: (ctx, payload) => {
          return {
            method: 'patch',
            url: `/users/${ctx.state.user.id}/business`,
            params: _.omit(payload, ['id', 'truliooKybDetails']),
          };
        },
        mutation: (state, { response }) => {
          setBusinessState(state, response);
        },
      },
      // TODO (177347): remove transform fields method when cleaning up MU epic
      UPDATE_NON_PRIMARY_USER: {
        action: (ctx, payload) => {
          if (ctx.rootGetters.isBausIntegrationEnabled) {
            return {
              requestFunc: () =>
                usersApi.updateUser({
                  id: payload.id,
                  businessId: ctx.getters.businessId,
                  patchUserDto: transformWriteUserFields(payload, true),
                }),
            };
          }

          const nonUpdatedOwners = ctx.getters.businessOwners.filter(
            (owner) => owner.id !== payload.id,
          );
          const params = {
            owners: [...nonUpdatedOwners, payload].map((owner) =>
              transformWriteUserFields(owner),
            ),
          };
          return {
            method: 'patch',
            url: `/users/${ctx.state.user.id}/business`,
            params,
          };
        },
        mutation: (state, { response }) => {
          // SC-178330 - response.owners will no longer be returned when integration with BAUS is done
          if (response.owners) {
            setOwnersState(state, response.owners);
          } else {
            const nonUpdatedOwners = state.owners.filter(
              (owner) => owner.id !== response.id,
            );
            setOwnersState(state, [...nonUpdatedOwners, response]);
          }
        },
      },
      CREATE_NON_PRIMARY_USER: {
        action: (ctx, payload) => {
          if (ctx.rootGetters.isBausIntegrationEnabled) {
            return {
              requestFunc: () =>
                usersApi.create({
                  createRequest: {
                    ...transformWriteUserFields(payload, true),
                    businessId: ctx.getters.businessId,
                    id: undefined,
                  },
                }),
              returnResponse: true,
            };
          }
          const newOwner = { ...payload, id: uuidv4() }; // baus creates new uuid, cb-api does not
          const allOwners = [...ctx.getters.businessOwners, newOwner];
          const params = {
            owners: allOwners.map((owner) => transformWriteUserFields(owner)),
          };
          return {
            method: 'patch',
            url: `/users/${ctx.state.user.id}/business`,
            params,
            returnResponse: true,
          };
        },
        mutation: (state, { response }) => {
          // SC-178330 - response.owners will no longer be returned when integration with BAUS is done
          if (response.owners) {
            setOwnersState(state, response.owners);
          } else {
            setOwnersState(state, [...state.owners, response]);
          }
        },
      },
      DELETE_NON_PRIMARY_USER: {
        action: (ctx, payload) => {
          if (ctx.rootGetters.isBausIntegrationEnabled) {
            return {
              requestFunc: () =>
                usersApi.deleteUser({
                  id: payload.id,
                  deleteUserRequestDto: {
                    businessId: ctx.getters.businessId,
                  },
                }),
            };
          }
          // SC-178330 - Remove when integration with BAUS is done.
          // Updating user in cb-api requires the complete array of owners
          const nonDeletedOwners = ctx.getters.businessOwners
            .filter((owner) => owner.id !== payload.id)
            .map((owner) => transformWriteUserFields(owner));
          return {
            method: 'patch',
            url: `/users/${ctx.state.user.id}/business`,
            params: {
              owners: nonDeletedOwners,
            },
          };
        },
        mutation: (state, { response }) => {
          // SC-178330 - response.owners will no longer be returned when integration with BAUS is done
          if (response.owners) {
            setOwnersState(state, response.owners);
          } else {
            const nonDeletedOwners = state.owners.filter(
              (owner) => owner.id !== response.id,
            );
            setOwnersState(state, nonDeletedOwners);
          }
        },
      },
      DELETE_USER_VERIFICATION: {
        action: (ctx, payload) =>
          diligenceServiceVerifications.documentVerificationsControllerRemove({
            externalId: payload.externalId,
            externalIdType: payload.externalIdType,
          }),
      },
      UPDATE_USER_BUSINESS_TO_CORE: {
        action: (ctx) => ({
          method: 'patch',
          url: `/businesses/${ctx.state.business.id}/product-segment`,
          params: { productSegment: 'core' },
        }),
        mutation: (state, { response }) => {
          setBusinessState(state, response);
        },
      },
      SEARCH_FOR_BUSINESS: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/search`,
          params: payload,
          options: { timeout: 80000 },
          returnResponse: true,
        }),
      },
      GET_BUSINESS_ACCOUNT_MANAGER: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/businesses/${ctx.state.business.id}/account-manager`,
        }),
        mutation: (state, { response }) => {
          state.businessAccountManager = response;
        },
      },
      ASSIGN_ACCOUNT_MANAGER: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/${ctx.state.business.id}/change-account-manager`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.businessAccountManager = response;
        },
      },
      TRIGGER_OWNER_KYC_ACTION: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/${ctx.state.business.id}/owner-kyc`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          setBusinessState(state, response);
          setOwnersState(state, response.owners);
        },
      },
      CHANGE_PRODUCT_SEGMENT: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/businesses/${ctx.state.business.id}/product-segment`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.business = response;
        },
      },
      GET_TAGS: {
        action: (ctx) => ({
          method: 'get',
          url: `/businesses/${ctx.state.business.id}/tags`,
        }),
        mutation: (state, { response }) => {
          state.businessTags = _.keyBy(response, 'id');
        },
      },
      CREATE_NEW_TAG: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/${ctx.state.business.id}/tag`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.businessTags[response.id] = response;
        },
      },
      DEACTIVATE_TAG: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/businesses/${ctx.state.business.id}/tag/deactivate`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.businessTags[response.id] = response;
        },
      },
      // CLEARCOM ONLY
      ADD_USER_AGREEMENT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.state.user.id}/legal-agreement`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.user.legalAgreementVersion = response.version;
        },
      },
      SEND_EMAIL_VERIFICATION_EMAIL: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.state.user.id}/send-email-verification-email`,
          params: payload,
        }),
      },
      VERIFY_USER_EMAIL: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.state.user.id}/verify-email`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          if (!response?.success) {
            state.user.emailVerificationFailed = response?.success;
          } else {
            analytics.track('payments_signup_viewed_confirm_email');
            state.user.emailVerifiedAt = response.email_verified_at;
          }
          storage.removeItem('cb-verification-hash');
        },
      },
      REMOVE_PERSONAL_DATA: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.state.user.id}/request-remove-personal-data`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.user.deletedAt = new Date();
        },
      },
      REQUEST_PRODUCT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.state.user.id}/request-product`,
          params: payload,
        }),
      },
      CONFIRM_PERSONAL_DATA_REMOVAL: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.state.user.id}/confirm-remove-personal-data`,
        }),
        mutation: (state, { response }) => {
          state.user = response.user;
        },
      },
      EXPORT_PERSONAL_DATA: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${ctx.state.user.id}/export-personal-data`,
          params: payload,
        }),
      },
      UPDATE_BUSINESS_CATEGORY: {
        action: (ctx, payload) => {
          return {
            method: 'post',
            url: `/businesses/${ctx.rootGetters.businessId}/category`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          state.business.categoryId = response.id;
        },
      },
      STORE_USER_ATTRIBUTION: {
        action: (ctx, payload) => {
          return {
            method: 'post',
            url: `/tracking/user-attribution`,
            params: {
              userId: payload.userId,
              urlParams: payload.urlParams,
              authType: payload.authType,
            },
          };
        },
      },
    },
    {
      mutations: {
        UPDATE_BUSINESS_PARAMS: (state, params) => {
          setBusinessState(state, { ...state.business, ...params });
        },
        UPDATE_USER_PARAMS: (state, params) => {
          setUserState(state, { ...state.user, ...params });
        },
        UPDATE_BUSINESS_ADDRESS_PARAMS: (state, params) => {
          state.business.address = {
            ...state.business.address,
            ...params,
          };
        },
        UPDATE_BUSINESS_SHIPPING_ADDRESS_PARAMS: (state, params) => {
          state.business.shippingAddress = {
            ...state.business.shippingAddress,
            ...params,
          };
        },
        SET_CURRENT_USER: (state, user) => {
          setUserState(state, user);
          // TODO (SC-178331): remove condition when we rollout isBausIntegrationEnabled
          if (user.business) {
            setBusinessState(state, user.business);
            setOwnersState(state, user.business.owners);
          }
          // add user/business ids to Sentry errors
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: user.id,
              businessId: user.businessId,
              email: user.email,
            });
          });
        },
        USER_LOGOUT: (state) => {
          // clear Vuex store state
          state.user = {};
          state.business = {};
          // reset analytics
          analytics.reset();
        },
      },
      actions: {
        UPDATE_USER_PARAMS: ({ commit }, params) => {
          commit('UPDATE_USER_PARAMS', params);
        },
        UPDATE_BUSINESS_PARAMS: ({ commit }, params) => {
          commit('UPDATE_BUSINESS_PARAMS', params);
        },
      },
    },
  ),
};
