// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_rwM0_{max-width:335px;margin:auto auto}.upload-container_IrgZB{margin-top:40px}.upload-info_xOZCA{padding-bottom:6px;font-size:14px;color:#666}.upload-info_xOZCA i{font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_rwM0_",
	"upload-container": "upload-container_IrgZB",
	"upload-info": "upload-info_xOZCA"
};
export default ___CSS_LOADER_EXPORT___;
