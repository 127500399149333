/* eslint-disable no-param-reassign */
import Axios from 'axios';
import _ from 'lodash';
import camelizeKeysDeep from 'camelcase-keys-deep';
import { auth0 } from '@/utils/auth0';
import { getParsedMessage } from '../data/error-messages';

export const paymentsRequest = Axios.create({
  timeout: process.env.API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * error.response.data.message.message be either
 * - string
 * - string[]
   - {
        field: string,
        code: string,
        message: string,
        params: {}
      }[]
 */
function isCurrencyCloudError(unknown) {
  return Array.isArray(unknown) && !!unknown[0] && unknown[0].message;
}

function parseCurrencyCloudError(ccError) {
  return ccError.map((x) => x.message);
}

function handleBeneficiaryServiceError(error) {
  const path = 'response.data.message'; // message inception
  const { message } = _.get(error, path) ?? {};
  if (message && isCurrencyCloudError(message)) {
    _.set(error, path, parseCurrencyCloudError(message));
  } else if (error.response.data.message.errorCode) {
    const userFriendlyMessage = getParsedMessage({
      errorCode: error.response.data.message.errorCode,
      details: error.response.data.message.details,
    });

    // want to keep error object intact so we can read the error code in components
    _.set(error, 'response.data', {
      ...error.response.data.message,
      message: userFriendlyMessage,
    });
  }

  return Promise.reject(error);
}

paymentsRequest.interceptors.request.use(async (config) => {
  // in local dev, use webpack's proxy to call bills api
  config.url = `${process.env.BILLS_API_URL}${config.url}`;
  const token = await auth0.getAccessTokenSilently();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

paymentsRequest.interceptors.response.use((response) => {
  if (
    response.config.url.match(/.*\/v[12]\/.*/) &&
    response.headers['content-type'].indexOf('application/json') === 0
  ) {
    response.data = camelizeKeysDeep(response.data);
  }
  return response;
}, handleBeneficiaryServiceError);
