<template>
  <div>
    <UiMsg v-if="errorMessage" type="error">
      {{ errorMessage }}
    </UiMsg>
    <Box :class="$style.box">
      <VeeForm
        class="c-form"
        :class="$style.form"
        :initial-values="initialValues"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="c-form__row">
          <InputTextGroup
            name="firstName"
            :label="$t('common.firstName')"
            :disabled="isPrimary && !!advanceContractUserSigned"
            @keydown="validateName"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'firstName');
                trackEvent('first_name_capture');
              }
            "
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="lastName"
            :label="$t('common.lastName')"
            :disabled="isPrimary && !!advanceContractUserSigned"
            @keydown="validateName"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'lastName');
              }
            "
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="email"
            label="Email"
            :disabled="isPrimary"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'email');
              }
            "
          />
        </div>
        <div class="c-form__controls">
          <DSButton
            v-if="canGoBack"
            class="p-button-link mr-auto"
            data-cy="back-button"
            style="padding-left: 0"
            :class="$style['button-link']"
            @click="handleGoBack"
          >
            <svg
              :class="$style['back-button']"
              v-html="require('@/assets/icons/back-icon.svg?raw')"
            ></svg>
          </DSButton>
          <DSButton
            v-else
            label="Discard"
            class="p-button-link"
            data-cy="discard-button"
            style="padding-left: 0"
            :class="$style['button-link']"
            @click="handleDiscard"
          />
          <DSButton
            ref="submit"
            label="Continue"
            type="submit"
            :loading="isLoading"
            :class="$style.button"
          />
        </div>
      </VeeForm>
    </Box>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { isMobileMixin } from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import analytics from '@/utils/analytics';
import { CONTACT_SUPPORT_MSG, getParsedMessage } from '@/data/error-messages';
import UiMsg from '@/components/UiMsg';
import Box from '@/components/Box';
import { ENVIRONMENTS } from '@/data/environments';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DSButton from '@clearbanc/clear-components/button';
import { objectDiff } from '@/utils/object-comparison';

export default {
  components: {
    UiMsg,
    Box,
    VeeForm,
    InputTextGroup,
    DSButton,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      idValidationErrorMsg: this.$t(
        'components.formOwnersStepOne.idValidationErrorMsg',
      ),
      schema: object({
        firstName: string().nullable().required(this.$t('common.required')),
        lastName: string().nullable().required(this.$t('common.required')),
        email: string().nullable().required(this.$t('common.required')).email(),
      }),
      isLoading: false,
      errorMessage: null,
      originalOwner: null,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOwner',
      'user',
      'advanceInNegotiation',
      'advanceContracts',
      'contactDetails',
      'businessOwners',
      'allOwners',
      'isOwnerInfoComplete',
      'isBausIntegrationEnabled',
    ]),
    ...mapRequestStatuses({
      updateNonPrimaryUserRequest: ['UPDATE_NON_PRIMARY_USER'],
      createNonPrimaryUserRequest: ['CREATE_NON_PRIMARY_USER'],
      updateUserRequest: 'UPDATE_USER_PROFILE',
      deleteUserRequest: 'DELETE_NON_PRIMARY_USER',
    }),
    isPrimary() {
      return !!this.selectedOwner?.isPrimary;
    },
    advanceContractUserSigned() {
      return this.advanceContracts[this.advanceInNegotiation.id]?.userSignedAt;
    },
    routerParams() {
      return this.$route.params;
    },
    initialValues() {
      return {
        firstName: this.selectedOwner?.firstName ?? '',
        lastName: this.selectedOwner?.lastName ?? '',
        email: this.selectedOwner?.email ?? '',
      };
    },
    canGoBack() {
      return (
        this.selectedOwner &&
        (this.isPrimary ||
          this.selectedOwner.id === 'new' || // this is a temp id for new users
          this.isOwnerInfoComplete(this.selectedOwner.id))
      );
    },
  },
  async beforeMount() {
    if (!this.selectedOwner && this.$route.params?.id) {
      await this.$store.dispatch('SET_SELECTED_OWNER', this.$route.params.id);
    }
    this.originalOwner = _.cloneDeep(this.selectedOwner);
  },
  methods: {
    displayError(error) {
      this.errorMessage =
        error || CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    validateName(event) {
      const nameRegex = /^([a-z.']+\s?)+$/i;
      if (!nameRegex.test(event.key)) {
        event.preventDefault();
      }
    },
    updateOwnerParams(val, name) {
      this.$store.dispatch('UPDATE_OWNER_PARAMS', { [name]: val });
    },
    trackEvent(name, props) {
      analytics.track(`fe_${name}`, { ...this.eventTrackingFields, ...props });
    },
    checkForEmailError() {
      if (this.selectedOwner?.email) {
        const businessHasOwnerWithSameEmail = this.allOwners.some(
          (owner) =>
            owner.email === this.selectedOwner.email &&
            owner.id !== this.selectedOwner.id,
        );
        if (businessHasOwnerWithSameEmail) {
          this.displayError('This email is already in use.');
        }
        return businessHasOwnerWithSameEmail;
      }
      return false;
    },
    async saveUser(owner) {
      const { changedProps } = objectDiff(owner, this.originalOwner);
      if (!Object.keys(changedProps).length) {
        this.$emit('validationSuccess');
        return;
      }
      if (this.isBausIntegrationEnabled) {
        if (Object.keys(changedProps).length) {
          await this.$store.dispatchApiAction(
            'UPDATE_USER_PROFILE',
            changedProps,
          );
        }
      } else {
        await this.$store.dispatchApiAction('UPDATE_USER_PROFILE', owner);
      }
      if (this.updateUserRequest.isError) {
        const errorOnUpdating = getParsedMessage(this.updateUserRequest.error);
        this.displayError(errorOnUpdating);
        this.$emit('validationError', errorOnUpdating);
        return;
      }
      this.$emit('validationSuccess');
    },
    async saveSecondaryUser(owner) {
      const existingOwner = this.businessOwners.some(
        (businessOwner) => businessOwner.id === owner.id,
      );

      const updatedOwnerData = {
        firstName: owner.firstName,
        lastName: owner.lastName,
        email: owner.email,
        ...((existingOwner || !this.isBausIntegrationEnabled) && {
          id: owner.id,
        }),
      };

      let error;
      if (!existingOwner) {
        const newUser = await this.$store.dispatchApiAction(
          'CREATE_NON_PRIMARY_USER',
          updatedOwnerData,
        );

        error = this.createNonPrimaryUserRequest.error;
        if (!error) {
          // SC-177347 remove this condition when we rollout multi user
          const createdId = this.isBausIntegrationEnabled
            ? newUser.id
            : newUser.owners?.find(({ email }) => email === owner.email)?.id;
          await this.$store.dispatch('SET_SELECTED_OWNER', createdId);
        }
      } else if (this.isBausIntegrationEnabled) {
        const { changedProps } = objectDiff(
          updatedOwnerData,
          this.originalOwner,
        );
        if (Object.keys(changedProps).length) {
          await this.$store.dispatchApiAction('UPDATE_NON_PRIMARY_USER', {
            ...changedProps,
            id: owner.id,
          });
          error = this.updateNonPrimaryUserRequest.error;
        }
      } else {
        await this.$store.dispatchApiAction('UPDATE_NON_PRIMARY_USER', owner);
      }

      if (error) {
        const errorOnUpdating = getParsedMessage(error);
        this.displayError(errorOnUpdating);
        this.$emit('validationError', errorOnUpdating);
        return;
      }
      this.$emit('validationSuccess');
    },
    async onSubmit() {
      // skip check in unit tests
      if (process.env.CLEARBANC_ENV !== ENVIRONMENTS.TEST) {
        if (await this.hasError()) {
          return;
        }
      }
      window.scrollTo(0, 0);
      if (!this.checkForEmailError()) {
        this.isLoading = true;
        this.isPrimary
          ? await this.saveUser(this.selectedOwner)
          : await this.saveSecondaryUser(this.selectedOwner);
        this.isLoading = false;
      }
    },
    async handleGoBack() {
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS,
      });
      if (this.selectedOwner) {
        await this.$store.dispatch('UNSELECT_OWNER');
      }
    },
    async handleDiscard() {
      await this.$store.dispatchApiAction(
        'DELETE_NON_PRIMARY_USER',
        this.selectedOwner,
      );
      if (this.deleteUserRequest.isSuccess) {
        this.handleGoBack();
      } else {
        this.displayError();
      }
    },
  },
};
</script>

<style lang="less" module>
.form {
  width: 330px;
}

.back-button {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: auto auto auto 0;
}

.button {
  min-width: 186px;
}
</style>
