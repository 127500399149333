import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    plaidInstitutions: {},
  },
  getters: {
    plaidInstitutions: (state) => {
      return state.plaidInstitutions;
    },
  },
  ...buildApiActions({
    POST_PLAID_PROXY: {
      action: (ctx, payload) => {
        return {
          method: 'post',
          url: `/plaid-proxy`,
          params: { ...payload, country_codes: ['CA', 'US'] },
          returnResponse: true,
        };
      },
      mutation: (state, { response }) => {
        if (response.institution) {
          state.plaidInstitutions[response.institution.name] =
            response.institution;
        }
      },
    },
  }),
};
