export function getPercent(value) {
  return `${+(value * 100).toFixed(2)}%`;
}

export function formatTwoDecimals(value) {
  return (Math.round(value * 100) / 100).toFixed(2);
}

// Helper function to check if the digits are sequential
export function isSequential(value) {
  const validValue = value ? `${value}` : '';
  return validValue
    .split('')
    .map(Number)
    .every((digit, index, array) =>
      index ? digit === array[index - 1] + 1 : true,
    );
}

// Helper function to check if the digits are repetitive
export function isRepetitive(value) {
  const validValue = value ? `${value}` : '';
  return validValue.split('').every((char) => char === validValue[0]);
}
