// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[class^=p-dialog]{max-width:none}body .footer_fFhdl button.footer__button_uRXL9{margin:0;width:100%}body .footer_fFhdl button.footer__button_uRXL9+button.footer__button_uRXL9{margin-top:16px}@media (min-width:700px){.footer_fFhdl{min-width:560px}body .footer_fFhdl button.footer__button_uRXL9{margin:0;width:calc(50% - 8px);white-space:nowrap}body .footer_fFhdl button.footer__button_uRXL9+button.footer__button_uRXL9{margin:0 0 0 16px}}.warning-message_GQLvl{margin-bottom:25px}.banks_Inb6Q{max-width:560px;display:flex;flex-wrap:wrap}.banks__item_XJCCr{width:100%;margin-top:16px}.banks__item_XJCCr:focus{outline:none}.banks__item__image_Wee0t{width:175px;height:80px;border:1px solid red;display:block}.banks__item__title_AYh_d{display:block}@media (min-width:400px) and (max-width:699px){.banks__item_XJCCr{width:calc(50% - 14px)}.banks__item_XJCCr+.banks__item_XJCCr{margin-left:14px}.banks__item_XJCCr:nth-child(-n+2){margin-top:0}.banks__item_XJCCr:nth-child(3n){margin-left:0}}@media (min-width:700px){.banks__item_XJCCr{width:calc(33.3% - 14px);margin-left:14px}.banks__item_XJCCr:nth-child(-n+3){margin-top:0}.banks__item_XJCCr:nth-child(4n){margin-left:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_fFhdl",
	"footer__button": "footer__button_uRXL9",
	"warning-message": "warning-message_GQLvl",
	"banks": "banks_Inb6Q",
	"banks__item": "banks__item_XJCCr",
	"banks__item__image": "banks__item__image_Wee0t",
	"banks__item__title": "banks__item__title_AYh_d"
};
export default ___CSS_LOADER_EXPORT___;
