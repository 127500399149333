// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".large-icon_jybVm{margin-top:10px}.large-icon_jybVm svg{height:100px;width:100%;display:inline-block}.btn-container_B5IlY{text-align:center;margin:0}.modal-content_CP6GR{max-width:700px;text-align:center;margin:10px}.subtitle-container_exuEu,.title-container_YSmXK,.title-container_YSmXK h1{margin:0}.subtitle-container_exuEu p{margin:16px 0 24px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"large-icon": "large-icon_jybVm",
	"btn-container": "btn-container_B5IlY",
	"modal-content": "modal-content_CP6GR",
	"subtitle-container": "subtitle-container_exuEu",
	"title-container": "title-container_YSmXK"
};
export default ___CSS_LOADER_EXPORT___;
